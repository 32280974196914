// React imports and libraries
import { useEffect, useState, useRef } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

// API calls / Slices imports
import { fetchMaterialsPrice } from '../../slices/materialsSlice';
// Utility functions, data and styles
import { groupBySection } from '../../hooks/groupBySection';

// images and icons
import leftLeanToIcon from '../../assets/left-lean-to.svg';
import centerSectionIcon from '../../assets/center-section.svg';
import frontLeanToIcon from '../../assets/front-lean-to.svg';
import rightLeanToIcon from '../../assets/right-lean-to.svg';
import backLeanToIcon from '../../assets/back-lean-to.svg';
import door from '../../assets/door-order.svg';
import window from '../../assets/window-order.svg';
import frameout from '../../assets/frameout-order.svg';
import garageDoor from '../../assets/garage-order.svg';
import rollupDoor from '../../assets/rollup-order.svg';
import skylight from '../../assets/skylight-order.svg';
import { X } from 'react-feather';
import { on } from 'events';

export default function DoorWindows({
	onSelectionsChange,
	apiSelections = [],
}) {
	const prevDataRef = useRef();

	const dispatch = useDispatch();

	const [activeTab, setActiveTab] = useState('centerSection');

	const [doorslist, setDoorslist] = useState([]);
	const [windowslist, setWindowslist] = useState([]);
	const [frameoutlist, setFrameoutlist] = useState([]);
	// const [garageDoorlist, setGarageDoorlist] = useState([]);
	const [rollupDoorlist, setRollupDoorlist] = useState([]);

	console.log(frameoutlist, 'frameoutlist');

	const categories = {
		door: Array.isArray(doorslist.items)
			? doorslist.items.map((door) => door.name)
			: [],
		window: Array.isArray(windowslist.items)
			? windowslist.items.map((window) => window.name)
			: [],
		frameouts: Array.isArray(frameoutlist.items)
			? frameoutlist.items.map((frameout) => frameout.name)
			: [],
		garageDoor: ['Garage Door Type 1', 'Garage Door list'],
		rollupDoor: ['Rollup Door Type 1', 'Rollup list'],
		skylight: ['Standard Skylight'],
	};

	const buildingSides = ['Front End', 'Back End', 'Left Side', 'Right Side'];
	const skylightSides = ['Left Side', 'Right Side'];

	const { control, register, watch, reset } = useForm({
		defaultValues: {
			frontLeanTo: [],
			leftLeanTo: [],
			centerSection: [],
			rightLeanTo: [],
			backLeanTo: [],
		},
	});

	const data = watch();

	const {
		fields: frontLeanToFields,
		append: appendFrontLeanTo,
		remove: removeFrontLeanTo,
	} = useFieldArray({
		control,
		name: 'frontLeanTo',
	});
	const {
		fields: leftLeanToFields,
		append: appendLeftLeanTo,
		remove: removeLeftLeanTo,
	} = useFieldArray({
		control,
		name: 'leftLeanTo',
	});

	const {
		fields: centerSectionFields,
		append: appendCenterSection,
		remove: removeCenterSection,
	} = useFieldArray({
		control,
		name: 'centerSection',
	});

	const {
		fields: rightLeanToFields,
		append: appendRightLeanTo,
		remove: removeRightLeanTo,
	} = useFieldArray({
		control,
		name: 'rightLeanTo',
	});

	const {
		fields: backLeanToFields,
		append: appendBackLeanTo,
		remove: removeBackLeanTo,
	} = useFieldArray({
		control,
		name: 'backLeanTo',
	});

	const handleTabClick = (tabName) => {
		setActiveTab(tabName);
	};

	const addItem = (category) => {
		const appendMap = {
			frontLeanTo: appendFrontLeanTo,
			leftLeanTo: appendLeftLeanTo,
			centerSection: appendCenterSection,
			rightLeanTo: appendRightLeanTo,
			backLeanTo: appendBackLeanTo,
		};

		appendMap[activeTab]({
			category,
			materialId: '',
			type: categories[category][0], // Default to first type in category
			buildingSides: buildingSides[0], // Default to first side in category
			location: { left: '', right: '', top: '' },
			defaultPrice: 0,
		});
	};

	// Fetch materials price for frameout
	useEffect(() => {
		const fetchData = async () => {
			const doorsResponse = await dispatch(
				fetchMaterialsPrice({
					categoryId: 14,
					pageSize: 1000,
					page: 1,
					sortOrder: 'ASC',
				})
			);

			setDoorslist(doorsResponse?.payload); // Adjust according to your action's response structure

			const windowsResponse = await dispatch(
				fetchMaterialsPrice({
					categoryId: 15,
					pageSize: 1000,
					page: 1,
					sortOrder: 'ASC',
				})
			);
			setWindowslist(windowsResponse.payload); // Adjust according to your action's response structure

			const frameoutResponse = await dispatch(
				fetchMaterialsPrice({
					categoryId: 33,
					pageSize: 1000,
					inventory: false,
					page: 1,
					sortOrder: 'ASC',
				})
			);
			console.log(frameoutResponse, 'frameoutResponse');
			setFrameoutlist(frameoutResponse.payload); // Adjust according to your action's response structure

			// const garageDoorResponse = await dispatch(
			// 	fetchMaterialsPrice({
			// 		categoryId: 16,
			// 		pageSize: 1000,
			// 		page: 1,
			// 		sortOrder: 'ASC',
			// 	})
			// );
			// setGarageDoorlist(garageDoorResponse.payload); // Adjust according to your action's response structure

			const rollupDoorResponse = await dispatch(
				fetchMaterialsPrice({
					categoryId: 34,
					pageSize: 1000,
					page: 1,
					sortOrder: 'ASC',
				})
			);
			setRollupDoorlist(rollupDoorResponse.payload); // Adjust according to your action's response structure
		};

		fetchData();
	}, [dispatch]);

	// Collecting all the data from the form

	useEffect(() => {
		if (apiSelections.length) {
			const newDefaults = groupBySection(apiSelections);
			reset(newDefaults);
		}
	}, [apiSelections, reset]);

	// Whenever the form data changes, build newSelections
	useEffect(() => {
		console.log(data, 'data');
		const newSelections = [
			...data.frontLeanTo,
			...data.leftLeanTo,
			...data.centerSection,
			...data.rightLeanTo,
			...data.backLeanTo,
		].map((selection) => ({
			description: selection.type || 'Unknown',
			materialId: selection.materialId,
			quantity: 1,
			unitPrice: parseFloat(selection.defaultPrice) || 0,
			metadata: {
				defaultPrice: selection.unitPrice,
				locationLeft: selection.location?.left || 'Unknown',
				locationRight: selection.location?.right || 'Unknown',
				locationTop: selection.location?.top || 'Unknown',
				section: activeTab,
				buildingSides: selection.buildingSides || 'Unknown',
			},
		}));

		// Compare current data with previous data
		if (JSON.stringify(prevDataRef.current) !== JSON.stringify(data)) {
			onSelectionsChange(newSelections);
			prevDataRef.current = data; // Update the previous data reference
		}
	}, [data, onSelectionsChange]);

	return (
		<div className="new-order-form">
			<p className="instructions-p">
				Select a section or a storage section. Add items using the buttons
				below. Then, select the item on the wall to change its style, size, or
				features.
			</p>
			<div className="doors-windows-section">
				<ul className="tab-buttons">
					<li
						className={
							activeTab === 'leftLeanTo' ? 'tab-button active' : 'tab-button'
						}
						onClick={(j) => handleTabClick('leftLeanTo', j)}
					>
						<img src={leftLeanToIcon} alt="Icon" className="icon-dwtab" />
						<strong>Left Lean-to</strong>
					</li>
					<li
						className={
							activeTab === 'frontLeanTo' ? 'tab-button active' : 'tab-button'
						}
						onClick={(j) => handleTabClick('frontLeanTo', j)}
					>
						<img src={frontLeanToIcon} alt="Icon" className="icon-dwtab" />
						<strong>Front Lean-to</strong>
					</li>
					<li
						className={
							activeTab === 'centerSection' ? 'tab-button active' : 'tab-button'
						}
						onClick={(j) => handleTabClick('centerSection', j)}
					>
						<img src={centerSectionIcon} alt="Icon" className="icon-dwtab" />
						<strong>Center Section</strong>
					</li>
					<li
						className={
							activeTab === 'backLeanTo' ? 'tab-button active' : 'tab-button'
						}
						onClick={(j) => handleTabClick('backLeanTo', j)}
					>
						<img src={backLeanToIcon} alt="Icon" className="icon-dwtab" />
						<strong>Back Lean-to</strong>
					</li>
					<li
						className={
							activeTab === 'rightLeanTo' ? 'tab-button active' : 'tab-button'
						}
						onClick={(j) => handleTabClick('rightLeanTo', j)}
					>
						<img src={rightLeanToIcon} alt="Icon" className="icon-dwtab" />
						<strong>Right Lean-to</strong>
					</li>
				</ul>
				<h3>Add items to wall</h3>
				<p className="instructions-p">
					Item intersects with building leg, a header for support may be
					required at an additional cost.
				</p>
				<div className="tab-windows-doors">
					{activeTab === 'frontLeanTo' && (
						<>
							{/* Dynamically created input groups */}
							{frontLeanToFields.map((item, index) => (
								<div key={item.id}>
									<div className="inputs-container">
										<div className="input-group">
											<label className="fisrt-input">
												<img
													src={
														item.category === 'door'
															? door
															: item.category === 'frameouts'
															? frameout
															: item.category === 'garageDoor'
															? garageDoor
															: item.category === 'rollupDoor'
															? rollupDoor
															: item.category === 'skylight'
															? skylight
															: window
													}
													alt={item.type}
													className="icon-dwtab"
												/>
												{item.category === 'door'
													? 'Door'
													: item.category === 'frameouts'
													? 'Frameout'
													: item.category === 'garageDoor'
													? 'Garage Door'
													: item.category === 'rollupDoor'
													? 'Rollup Door'
													: item.category === 'skylight'
													? 'Skylight'
													: 'Window'}
											</label>
											<button
												type="button"
												className="remove-item"
												onClick={() => removeFrontLeanTo(index)}
											>
												<X size={18} />
											</button>
										</div>
										<div className="input-group">
											<div className="input-selection">
												<h3 className="group-title">
													{item.category === 'door'
														? 'Door'
														: item.category === 'frameouts'
														? 'Frameout'
														: item.category === 'garageDoor'
														? 'Garage Door'
														: item.category === 'rollupDoor'
														? 'Rollup Door'
														: item.category === 'skylight'
														? 'Skylight'
														: 'Window'}
												</h3>
												<select
													{...register(`frontLeanTo[${index}].type`)}
													className="select-group"
												>
													{categories[item.category] &&
													categories[item.category].length > 0 ? (
														categories[item.category].map((type, typeIndex) => (
															<option key={typeIndex} value={type}>
																{type}
															</option>
														))
													) : (
														<option>Loading...</option>
													)}
												</select>
												<input
													type="hidden"
													{...register(`frontLeanTo[${index}].id`)}
													value={item.id}
												/>
												<input
													type="hidden"
													{...register(`frontLeanTo[${index}].defaultPrice`)}
													value={item.defaultPrice}
												/>
											</div>
											<div className="input-selection">
												<h3 className="group-title">Side</h3>

												<select
													{...register(`frontLeanTo[${index}].buildingSides`)}
													className="select-group"
												>
													{(item.category === 'skylight'
														? skylightSides
														: buildingSides
													).map((side, sideIndex) => (
														<option key={sideIndex} value={side}>
															{side}
														</option>
													))}
												</select>
												<input
													type="hidden"
													{...register(`frontLeanTo[${index}].defaultPrice`)}
													value={item.defaultPrice}
												/>
												{console.log(item.defaultPrice, 'item.defaultPrice')}
												<input
													type="hidden"
													{...register(`frontLeanTo[${index}].categoryId`)}
													value={item.materialId}
												/>
											</div>

											<div className="input-selection">
												<h3 className="group-title">Location</h3>

												<div className="inp-col-size">
													<label>Left</label>
													<input
														{...register(`items[${index}].location.left`)}
														className="select-input"
													/>
												</div>

												<div className="inp-col-size">
													<label>Right</label>
													<input
														{...register(`items[${index}].location.right`)}
														className="select-input"
													/>
												</div>

												<div className="inp-col-size">
													<label>Top</label>
													<input
														{...register(`items[${index}].location.top`)}
														className="select-input"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
							<div className="door-window-buttons">
								<button
									type="button"
									onClick={() => addItem('door')}
									className="door-window-button"
								>
									<img src={door} alt="Door" className="icon-dwtab" />
									Door
								</button>
								<button
									type="button"
									onClick={() => addItem('window')}
									className="door-window-button"
								>
									<img src={window} alt="Window" className="icon-dwtab" />
									Window
								</button>
								<button
									type="button"
									onClick={() => addItem('frameouts')}
									className="door-window-button"
								>
									<img src={frameout} alt="Frameout" className="icon-dwtab" />
									Frameout
								</button>
								<button
									type="button"
									onClick={() => addItem('garageDoor')}
									className="door-window-button"
								>
									<img
										src={garageDoor}
										alt="Garage Door"
										className="icon-dwtab"
									/>
									Garage Door
								</button>
								<button
									type="button"
									onClick={() => addItem('rollupDoor')}
									className="door-window-button"
								>
									<img
										src={rollupDoor}
										alt="Rollup Door"
										className="icon-dwtab"
									/>
									Rollup Door
								</button>
								<button
									type="button"
									onClick={() => addItem('skylight')}
									className="door-window-button"
								>
									<img src={skylight} alt="skylight" className="icon-dwtab" />
									Skylight
								</button>
							</div>
						</>
					)}
					{activeTab === 'leftLeanTo' && (
						<>
							{/* Dynamically created input groups */}
							{leftLeanToFields.map((item, index) => (
								<div key={item.id}>
									<div className="inputs-container">
										<div className="input-group">
											<label className="fisrt-input">
												<img
													src={
														item.category === 'door'
															? door
															: item.category === 'frameouts'
															? frameout
															: item.category === 'garageDoor'
															? garageDoor
															: item.category === 'rollupDoor'
															? rollupDoor
															: item.category === 'skylight'
															? skylight
															: window
													}
													alt={item.type}
													className="icon-dwtab"
												/>
												{item.category === 'door'
													? 'Door'
													: item.category === 'frameouts'
													? 'Frameout'
													: item.category === 'garageDoor'
													? 'Garage Door'
													: item.category === 'rollupDoor'
													? 'Rollup Door'
													: item.category === 'skylight'
													? 'Skylight'
													: 'Window'}
											</label>
											<button
												type="button"
												className="remove-item"
												onClick={() => removeLeftLeanTo(index)}
											>
												<X size={18} />
											</button>
										</div>
										<div className="input-group">
											<div className="input-selection">
												<h3 className="group-title">
													{item.category === 'door'
														? 'Door'
														: item.category === 'frameouts'
														? 'Frameout'
														: item.category === 'garageDoor'
														? 'Garage Door'
														: item.category === 'rollupDoor'
														? 'Rollup Door'
														: item.category === 'skylight'
														? 'Skylight'
														: 'Window'}
												</h3>
												<select
													{...register(`leftLeanTo[${index}].type`)}
													className="select-group"
												>
													{categories[item.category] &&
													categories[item.category].length > 0 ? (
														categories[item.category].map((type, typeIndex) => (
															<option key={typeIndex} value={type}>
																{type}
															</option>
														))
													) : (
														<option>Loading...</option>
													)}
												</select>
											</div>
											<div className="input-selection">
												<h3 className="group-title">Side</h3>

												<select
													{...register(`leftLeanTo[${index}].buildingSides`)}
													className="select-group"
												>
													{(item.category === 'skylight'
														? skylightSides
														: buildingSides
													).map((side, sideIndex) => (
														<option key={sideIndex} value={side}>
															{side}
														</option>
													))}
												</select>
											</div>

											<div className="input-selection">
												<h3 className="group-title">Location</h3>

												<div className="inp-col-size">
													<label>Left</label>
													<input
														{...register(`items[${index}].location.left`)}
														className="select-input"
														placeholder="Auto"
													/>
												</div>

												<div className="inp-col-size">
													<label>Right</label>
													<input
														{...register(`items[${index}].location.right`)}
														className="select-input"
														placeholder="Auto"
													/>
												</div>

												<div className="inp-col-size">
													<label>Top</label>
													<input
														{...register(`items[${index}].location.top`)}
														className="select-input"
														placeholder="Auto"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
							<div className="door-window-buttons">
								<button
									type="button"
									onClick={() => addItem('door')}
									className="door-window-button"
								>
									<img src={door} alt="Door" className="icon-dwtab" />
									Door
								</button>
								<button
									type="button"
									onClick={() => addItem('window')}
									className="door-window-button"
								>
									<img src={window} alt="Window" className="icon-dwtab" />
									Window
								</button>
								<button
									type="button"
									onClick={() => addItem('frameouts')}
									className="door-window-button"
								>
									<img src={frameout} alt="Frameout" className="icon-dwtab" />
									Frameout
								</button>
								<button
									type="button"
									onClick={() => addItem('garageDoor')}
									className="door-window-button"
								>
									<img
										src={garageDoor}
										alt="Garage Door"
										className="icon-dwtab"
									/>
									Garage Door
								</button>
								<button
									type="button"
									onClick={() => addItem('rollupDoor')}
									className="door-window-button"
								>
									<img
										src={rollupDoor}
										alt="Rollup Door"
										className="icon-dwtab"
									/>
									Rollup Door
								</button>
								<button
									type="button"
									onClick={() => addItem('skylight')}
									className="door-window-button"
								>
									<img src={skylight} alt="skylight" className="icon-dwtab" />
									Skylight
								</button>
							</div>
						</>
					)}
					{activeTab === 'centerSection' && (
						<>
							{/* Dynamically created input groups */}
							{centerSectionFields.map((item, index) => (
								<div key={item.id}>
									<div className="inputs-container">
										<div className="input-group">
											<label className="fisrt-input">
												<img
													src={
														item.category === 'door'
															? door
															: item.category === 'frameouts'
															? frameout
															: item.category === 'garageDoor'
															? garageDoor
															: item.category === 'rollupDoor'
															? rollupDoor
															: item.category === 'skylight'
															? skylight
															: window
													}
													alt={item.type}
													className="icon-dwtab"
												/>
												{item.category === 'door'
													? 'Door'
													: item.category === 'frameouts'
													? 'Frameout'
													: item.category === 'garageDoor'
													? 'Garage Door'
													: item.category === 'rollupDoor'
													? 'Rollup Door'
													: item.category === 'skylight'
													? 'Skylight'
													: 'Window'}
											</label>
											<button
												type="button"
												className="remove-item"
												onClick={() => removeCenterSection(index)}
											>
												<X size={18} />
											</button>
										</div>
										<div className="input-group">
											<div className="input-selection">
												<h3 className="group-title">
													{item.category === 'door'
														? 'Door'
														: item.category === 'frameouts'
														? 'Frameout'
														: item.category === 'garageDoor'
														? 'Garage Door'
														: item.category === 'rollupDoor'
														? 'Rollup Door'
														: item.category === 'skylight'
														? 'Skylight'
														: 'Window'}
												</h3>
												<select
													{...register(`centerSection[${index}].type`)}
													className="select-group"
												>
													{categories[item.category] &&
													categories[item.category].length > 0 ? (
														categories[item.category].map((type, typeIndex) => (
															<option key={typeIndex} value={type}>
																{type}
															</option>
														))
													) : (
														<option>Loading...</option>
													)}
												</select>
											</div>
											<div className="input-selection">
												<h3 className="group-title">Side</h3>

												<select
													{...register(`centerSection[${index}].buildingSides`)}
													className="select-group"
												>
													{(item.category === 'skylight'
														? skylightSides
														: buildingSides
													).map((side, sideIndex) => (
														<option key={sideIndex} value={side}>
															{side}
														</option>
													))}
												</select>
											</div>

											<div className="input-selection">
												<h3 className="group-title">Location</h3>

												<div className="inp-col-size">
													<label>Left</label>
													<input
														{...register(`items[${index}].location.left`)}
														className="select-input"
													/>
												</div>

												<div className="inp-col-size">
													<label>Right</label>
													<input
														{...register(`items[${index}].location.right`)}
														className="select-input"
													/>
												</div>

												<div className="inp-col-size">
													<label>Top</label>
													<input
														{...register(`items[${index}].location.top`)}
														className="select-input"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
							<div className="door-window-buttons">
								<button
									type="button"
									onClick={() => addItem('door')}
									className="door-window-button"
								>
									<img src={door} alt="Door" className="icon-dwtab" />
									Door
								</button>
								<button
									type="button"
									onClick={() => addItem('window')}
									className="door-window-button"
								>
									<img src={window} alt="Window" className="icon-dwtab" />
									Window
								</button>
								<button
									type="button"
									onClick={() => addItem('frameouts')}
									className="door-window-button"
								>
									<img src={frameout} alt="Frameout" className="icon-dwtab" />
									Frameout
								</button>
								<button
									type="button"
									onClick={() => addItem('garageDoor')}
									className="door-window-button"
								>
									<img
										src={garageDoor}
										alt="Garage Door"
										className="icon-dwtab"
									/>
									Garage Door
								</button>
								<button
									type="button"
									onClick={() => addItem('rollupDoor')}
									className="door-window-button"
								>
									<img
										src={rollupDoor}
										alt="Rollup Door"
										className="icon-dwtab"
									/>
									Rollup Door
								</button>
								<button
									type="button"
									onClick={() => addItem('skylight')}
									className="door-window-button"
								>
									<img src={skylight} alt="skylight" className="icon-dwtab" />
									Skylight
								</button>
							</div>
						</>
					)}
					{activeTab === 'rightLeanTo' && (
						<>
							{/* Dynamically created input groups */}
							{rightLeanToFields.map((item, index) => (
								<div key={item.id}>
									<div className="inputs-container">
										<div className="input-group">
											<label className="fisrt-input">
												<img
													src={
														item.category === 'door'
															? door
															: item.category === 'frameouts'
															? frameout
															: item.category === 'garageDoor'
															? garageDoor
															: item.category === 'rollupDoor'
															? rollupDoor
															: item.category === 'skylight'
															? skylight
															: window
													}
													alt={item.type}
													className="icon-dwtab"
												/>
												{item.category === 'door'
													? 'Door'
													: item.category === 'frameouts'
													? 'Frameout'
													: item.category === 'garageDoor'
													? 'Garage Door'
													: item.category === 'rollupDoor'
													? 'Rollup Door'
													: item.category === 'skylight'
													? 'Skylight'
													: 'Window'}
											</label>
											<button
												type="button"
												className="remove-item"
												onClick={() => removeRightLeanTo(index)}
											>
												<X size={18} />
											</button>
										</div>
										<div className="input-group">
											<div className="input-selection">
												<h3 className="group-title">
													{item.category === 'door'
														? 'Door'
														: item.category === 'frameouts'
														? 'Frameout'
														: item.category === 'garageDoor'
														? 'Garage Door'
														: item.category === 'rollupDoor'
														? 'Rollup Door'
														: item.category === 'skylight'
														? 'Skylight'
														: 'Window'}
												</h3>
												<select
													{...register(`rightLeanTo[${index}].type`)}
													className="select-group"
												>
													{categories[item.category] &&
													categories[item.category].length > 0 ? (
														categories[item.category].map((type, typeIndex) => (
															<option key={typeIndex} value={type}>
																{type}
															</option>
														))
													) : (
														<option>Loading...</option>
													)}
												</select>
											</div>
											<div className="input-selection">
												<h3 className="group-title">Side</h3>

												<select
													{...register(`rightLeanTo[${index}].buildingSides`)}
													className="select-group"
												>
													{(item.category === 'skylight'
														? skylightSides
														: buildingSides
													).map((side, sideIndex) => (
														<option key={sideIndex} value={side}>
															{side}
														</option>
													))}
												</select>
											</div>

											<div className="input-selection">
												<h3 className="group-title">Location</h3>

												<div className="inp-col-size">
													<label>Left</label>
													<input
														{...register(`items[${index}].location.left`)}
														className="select-input"
													/>
												</div>

												<div className="inp-col-size">
													<label>Right</label>
													<input
														{...register(`items[${index}].location.right`)}
														className="select-input"
													/>
												</div>

												<div className="inp-col-size">
													<label>Top</label>
													<input
														{...register(`items[${index}].location.top`)}
														className="select-input"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
							<div className="door-window-buttons">
								<button
									type="button"
									onClick={() => addItem('door')}
									className="door-window-button"
								>
									<img src={door} alt="Door" className="icon-dwtab" />
									Door
								</button>
								<button
									type="button"
									onClick={() => addItem('window')}
									className="door-window-button"
								>
									<img src={window} alt="Window" className="icon-dwtab" />
									Window
								</button>
								<button
									type="button"
									onClick={() => addItem('frameouts')}
									className="door-window-button"
								>
									<img src={frameout} alt="Frameout" className="icon-dwtab" />
									Frameout
								</button>
								<button
									type="button"
									onClick={() => addItem('garageDoor')}
									className="door-window-button"
								>
									<img
										src={garageDoor}
										alt="Garage Door"
										className="icon-dwtab"
									/>
									Garage Door
								</button>
								<button
									type="button"
									onClick={() => addItem('rollupDoor')}
									className="door-window-button"
								>
									<img
										src={rollupDoor}
										alt="Rollup Door"
										className="icon-dwtab"
									/>
									Rollup Door
								</button>
								<button
									type="button"
									onClick={() => addItem('skylight')}
									className="door-window-button"
								>
									<img src={skylight} alt="skylight" className="icon-dwtab" />
									Skylight
								</button>
							</div>
						</>
					)}
					{activeTab === 'backLeanTo' && (
						<>
							{/* Dynamically created input groups */}
							{backLeanToFields.map((item, index) => (
								<div key={item.id}>
									<div className="inputs-container">
										<div className="input-group">
											<label className="fisrt-input">
												<img
													src={
														item.category === 'door'
															? door
															: item.category === 'frameouts'
															? frameout
															: item.category === 'garageDoor'
															? garageDoor
															: item.category === 'rollupDoor'
															? rollupDoor
															: item.category === 'skylight'
															? skylight
															: window
													}
													alt={item.type}
													className="icon-dwtab"
												/>
												{item.category === 'door'
													? 'Door'
													: item.category === 'frameouts'
													? 'Frameout'
													: item.category === 'garageDoor'
													? 'Garage Door'
													: item.category === 'rollupDoor'
													? 'Rollup Door'
													: item.category === 'skylight'
													? 'Skylight'
													: 'Window'}
											</label>
											<button
												type="button"
												className="remove-item"
												onClick={() => removeBackLeanTo(index)}
											>
												<X size={18} />
											</button>
										</div>
										<div className="input-group">
											<div className="input-selection">
												<h3 className="group-title">
													{item.category === 'door'
														? 'Door'
														: item.category === 'frameouts'
														? 'Frameout'
														: item.category === 'garageDoor'
														? 'Garage Door'
														: item.category === 'rollupDoor'
														? 'Rollup Door'
														: item.category === 'skylight'
														? 'Skylight'
														: 'Window'}
												</h3>
												<select
													{...register(`backLeanTo[${index}].type`)}
													className="select-group"
												>
													{categories[item.category] &&
													categories[item.category].length > 0 ? (
														categories[item.category].map((type, typeIndex) => (
															<option key={typeIndex} value={type}>
																{type}
															</option>
														))
													) : (
														<option>Loading...</option>
													)}
												</select>
											</div>
											<div className="input-selection">
												<h3 className="group-title">Side</h3>

												<select
													{...register(`backLeanTo[${index}].buildingSides`)}
													className="select-group"
												>
													{(item.category === 'skylight'
														? skylightSides
														: buildingSides
													).map((side, sideIndex) => (
														<option key={sideIndex} value={side}>
															{side}
														</option>
													))}
												</select>
											</div>
											<div className="input-selection">
												<h3 className="group-title">Location</h3>

												<div className="inp-col-size">
													<label>Left</label>
													<input
														{...register(`items[${index}].location.left`)}
														className="select-input"
													/>
												</div>

												<div className="inp-col-size">
													<label>Right</label>
													<input
														{...register(`items[${index}].location.right`)}
														className="select-input"
													/>
												</div>

												<div className="inp-col-size">
													<label>Top</label>
													<input
														{...register(`items[${index}].location.top`)}
														className="select-input"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
							<div className="door-window-buttons">
								<button
									type="button"
									onClick={() => addItem('door')}
									className="door-window-button"
								>
									<img src={door} alt="Door" className="icon-dwtab" />
									Door
								</button>
								<button
									type="button"
									onClick={() => addItem('window')}
									className="door-window-button"
								>
									<img src={window} alt="Window" className="icon-dwtab" />
									Window
								</button>
								<button
									type="button"
									onClick={() => addItem('frameouts')}
									className="door-window-button"
								>
									<img src={frameout} alt="Frameout" className="icon-dwtab" />
									Frameout
								</button>
								<button
									type="button"
									onClick={() => addItem('garageDoor')}
									className="door-window-button"
								>
									<img
										src={garageDoor}
										alt="Garage Door"
										className="icon-dwtab"
									/>
									Garage Door
								</button>
								<button
									type="button"
									onClick={() => addItem('rollupDoor')}
									className="door-window-button"
								>
									<img
										src={rollupDoor}
										alt="Rollup Door"
										className="icon-dwtab"
									/>
									Rollup Door
								</button>
								<button
									type="button"
									onClick={() => addItem('skylight')}
									className="door-window-button"
								>
									<img src={skylight} alt="skylight" className="icon-dwtab" />
									Skylight
								</button>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
