import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	ChevronDown,
	ChevronRight,
	Download,
	PlusCircle,
	X,
} from 'react-feather';
import { useSelector } from 'react-redux';
import ProgressBar from './ProgressBar';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
	fetchOrders,
	fetchOrderById,
	updateOrderStatus,
	updateOrdersNotes,
} from '../slices/ordersSlice';
import { fetchSelf, fetchUsers } from '../slices/userSlice';

import axios from 'axios';
import usePopup from '../hooks/popuphook';
import BillingDetails from './clients/BillingDetails';

export default function OrderDetails({ order, onClose }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const orderDetails = useSelector((state) => state.orders.order);
	const orders = useSelector((state) => state.orders.items);
	const currentUser = useSelector((state) => state.auth.self);
	// const client = useSelector((state) => state.clients.clientById);
	const usersList = useSelector((state) => state.auth?.users?.items);

	// Notes
	useEffect(() => {
		dispatch(fetchUsers({ pageSize: 100 }));
		dispatch(fetchSelf());
	}, [dispatch]);

	const onSubmitFile = async (data) => {
		// Check if newText is not empty
		const newNote = {
			userId: currentUser.id,
			createdAt: new Date().toISOString(),
			text: data.textNote,
			attachments: urlState.map((url) => ({
				description: 'Attachment',
				url: url,
			})),
		};

		// Add the new note to the existing notes
		const newNotes = [...existingNotes, newNote];

		// dispatch the action to update the notes
		await dispatch(
			updateOrdersNotes({
				id: orderId,
				notes: newNotes,
			})
		);
		// Clear the input fields
		dispatch(fetchOrders(pageSize));
		setNewNoteText('');
		setUrlState([]);

		reset({
			textNote: '',
		});
		// Clear file inputs
		setFileInputs([0]);

		// Reset the form to clear the file input
		// Reset the form to clear the file input
		if (formRef.current) {
			formRef.current.reset();
		}

		dispatch(fetchOrderById(orderId));
	};

	// if current user and userList.id is the same return the user name userlist.name
	const getUserName = (userId) => {
		if (!userId) {
			return 'Unknown';
		}

		const user = usersList?.find(
			(user) => user?.id?.trim().toLowerCase() === userId.trim().toLowerCase()
		);

		return user ? user.firstName : 'Unknown';
	};

	// Form to update order status

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			status: orderDetails.status,
		},
	});

	const pageSize = 100;

	const { isOpen, openPopup, closePopup } = usePopup();
	const [editFrom, setEditForm] = useState(false);
	const api = process.env.REACT_APP_API_URL;
	const formRef = useRef();

	// read the users and set the account manager value to the user id and label to the user name from

	//  add more fileds to upload file
	const [fileInputs, setFileInputs] = useState([0]);
	// sending files
	const existingNotes = orderDetails?.notes || [];
	// here is the file url
	const [urlState, setUrlState] = useState([]);
	const [newNoteText, setNewNoteText] = useState('');
	// Uploading progress bar
	const [Msg, setMsg] = useState(null);
	const [progress, setProgress] = useState({ started: false, pc: 0, msg: '' });

	// upload and remove inputs
	const addFileInput = () => {
		setFileInputs([...fileInputs, fileInputs.length]);
	};
	const removeFileInput = () => {
		if (fileInputs.length > 1) {
			const newFileInputs = fileInputs.slice(0, -1);
			setFileInputs(newFileInputs);
		}
	};

	// upload file
	const fileUploadHandler = async (file) => {
		const token = localStorage.getItem('token');

		// upload file by file and wait for the response to copy the url
		const formData = new FormData();
		if (file) {
			// Create a new File object with the same file data but a modified name
			const newFile = new File([file], file.name.replace(/\s/g, '_'), {
				type: file.type,
			});

			formData.append('file', newFile);

			setMsg('Uploading...');
			setProgress({ started: true, pc: 0, msg: 'Uploading...' });
		}

		try {
			const response = await axios.post(`${api}/files/upload`, formData, {
				onUploadProgress: (progressEvent) => {
					const pc = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					setProgress({ started: true, pc, msg: 'Uploading...' });
				},
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${token}`,
				},
			});

			// console.log('response', response.data.fileUrl);
			setUrlState([...urlState, response.data.fileUrl]);
			setMsg('File uploaded successfully!');
			setProgress({
				started: false,
				pc: 0,
				msg: 'File uploaded successfully ready to send or add more files!',
			});
		} catch (error) {
			setMsg('Error uploading file!');
		}
	};

	const [sections, setSections] = useState({
		sale: false,
		client: false,
		structure: false,
		installer: false,
		notes: false,
		permits: false,
		invoice: false,
		recomendations: false,
		statussection: false,
	});

	const toggleSection = (section) => {
		setSections((prevSections) => ({
			...prevSections,
			[section]: !prevSections[section],
		}));
	};

	if (!order) return null;

	const orderId = orderDetails.id;

	const handleStatus = async (data) => {
		await dispatch(
			updateOrderStatus({
				orderId,
				status: Number(data.status), // Convert to number
			})
		);

		if (data.status === 6) {
			// Redirect to repair page
			console.log('Navigating to /repair');
			navigate('/repair');
			onClose();
		} else if (data.status === 1) {
			// Redirect to orders page
			console.log('Navigating to /orders');
			navigate('/orders');
			onClose();
		} else {
			// Close
			console.log('Closing without navigation');
			onClose();
		}
	};

	const totalEstimate = 1000; // Example dummy data
	const discount = 100; // Example dummy data
	const totalLegPrice = 200; // Example dummy data
	const cSHeight = 10; // Example dummy data
	const cSLength = 20; // Example dummy data
	const cSWidth = 30; // Example dummy data
	const totalRoofStylePrice = 400; // Example dummy data
	const totalRoofPitchPrice = 50; // Example dummy data
	const roofStyleValue = 'Gable'; // Example dummy data
	const roofPitchOptions = ['Low', 'Medium', 'High']; // Example dummy data

	const totalDiscount = discount ? discount : 0;
	const subTotal = totalLegPrice + totalEstimate - totalDiscount;
	const taxes = subTotal * 0.0775;
	const total = subTotal + subTotal * 0.0775;

	// Button to send to 3d Design design.bncaccess.com

	const sendToDesign = () => {
		const designUrl = `https://design.bncaccess.com/${orderDetails.id}`;
		window.open(designUrl, '_blank');
	};

	return (
		<div className="order-details-panel">
			<aside className="details-panel">
				<div className="order-number">
					<div className="block-left">
						<h3 className="order-number-title">Order {order.reference}</h3>
						<p className=" text-sm">
							<strong>Last updated</strong>:{' '}
							{new Date(orderDetails.updatedAt).toLocaleString('en-US', {
								month: '2-digit',
								day: '2-digit',
								year: '2-digit',
								hour: '2-digit',
								minute: '2-digit',
							})}
						</p>
					</div>
					<div className="block-right">
						<button onClick={onClose} className="close-btn">
							<X size={24} />
						</button>
					</div>
				</div>
				<div className="progress-guide">
					<div className="progress-guide-block w-full">
						<h2 className="progress-guide-title">
							<ProgressBar currentStatus={orderDetails.status} />
						</h2>
					</div>
				</div>

				<div className="tab-information">
					<div className="tab-information-block">
						<div className="title-block" onClick={() => toggleSection('sale')}>
							<h2 className="title-h2">Sales Representative</h2>
							<button>
								{sections.sale ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.sale && (
							<div className="content-block">
								<div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2 content-block">
									<p className="data-content">
										<strong>Full Name</strong>
										{orderDetails.salesman.fullName}
									</p>

									<p className="data-content">
										<strong>Email</strong>
										<a href={`mailto:${orderDetails.salesman.email}`}>
											{orderDetails.salesman.email}
										</a>
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="tab-information">
					<div className="tab-information-block">
						<div
							className="title-block"
							onClick={() => toggleSection('client')}
						>
							<h2 className="title-h2">Client Information</h2>
							<button>
								{sections.client ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.client && (
							<>
								<div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2 content-block">
									<p className="data-content">
										<strong>First Name</strong>
										{orderDetails.client.firstName}
									</p>
									<p className="data-content">
										<strong>Last Name</strong>
										<div>{orderDetails.client.lastName}</div>
									</p>
									<p className="data-content">
										<strong>Phone</strong>
										<a href={`tel:+1${orderDetails.client.phoneNumber}`}>
											{orderDetails.client.phoneNumber}
										</a>
									</p>
									<p className="data-content">
										<strong>Email</strong>
										<a href={`mailto:${orderDetails.client.email}`}>
											{orderDetails.client.email}
										</a>
									</p>
								</div>
								<div className="w-full block-subtitle">
									<h3 className="title-h2">Delivery Address</h3>
								</div>
								<div className="content-box grid grid-cols-2 content-block">
									<p className="data-content">
										<strong>Street</strong>
										<div>{orderDetails.address}</div>
									</p>
									<p className="data-content">
										<strong>City</strong>
										<div>{orderDetails.city}</div>
									</p>
									<p className="data-content">
										<strong>State</strong>
										<div>{orderDetails.state}</div>
									</p>
									<p className="data-content">
										<strong>Zipcode</strong>
										<div>{orderDetails.postalCode}</div>
									</p>
								</div>
							</>
						)}
					</div>
				</div>
				<div className="structure-details">
					<div className="structure-details-block">
						<div
							className="title-block"
							onClick={() => toggleSection('structure')}
						>
							<h2 className="title-h2">Structure Details</h2>
							<button>
								{sections.structure ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.structure && (
							<div className="price-cont">
								<p className="price-subtitle">Structure Details</p>
								<table className="price-summary">
									<tbody>
										<tr>
											<td>
												<strong>Base Price:</strong> {cSWidth}‘x{cSLength}‘
											</td>
											<td>
												${discount ? totalEstimate - discount : totalEstimate}
											</td>
										</tr>
										<tr>
											<td>
												<strong>Installation Surface:</strong> Concrete
											</td>
											<td>-</td>
										</tr>
										<tr>
											<td>
												<strong>Roof:</strong> Rawhide
											</td>
											<td>-</td>
										</tr>
										<tr>
											<td>
												<strong>Trim Colors:</strong> White
											</td>
											<td>-</td>
										</tr>
										<tr>
											<td>
												<strong>Roof Style:</strong>
												{roofStyleValue}
											</td>
											<td>
												$
												{totalRoofStylePrice?.toLocaleString('en-US', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}
											</td>
										</tr>
										<tr>
											<td>
												<strong>Roof Pitch:</strong>
											</td>
											<td>
												$
												{totalRoofPitchPrice?.toLocaleString('en-US', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}
											</td>
										</tr>
										<tr>
											<td>
												<strong>Roof Overhang:</strong> Standard
											</td>
											<td>-</td>
										</tr>
										<tr>
											<td>
												<strong>Trusses:</strong> Standard
											</td>
											<td>-</td>
										</tr>
										<tr>
											<td>
												<strong>Gauge:</strong> 14-Gauge Framing
											</td>
											<td>-</td>
										</tr>
										<tr>
											<td>
												<strong>Brace:</strong> Standard Brace
											</td>
											<td>-</td>
										</tr>
										{totalLegPrice ? (
											<tr>
												<td>
													<strong>Leg Height:</strong> {cSHeight}‘
												</td>
												<td>
													$
													{totalLegPrice?.toLocaleString('en-US', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
													})}
												</td>
											</tr>
										) : (
											''
										)}
									</tbody>
								</table>

								<div className="price-total-container">
									<div className="price-total">
										<p className="price-total-title">Building Estimate:</p>
										<p className="price-total-value">
											$
											{totalEstimate?.toLocaleString('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}
										</p>
									</div>
									{discount && (
										<div className="price-total">
											<p className="price-total-title">Discount:</p>
											<p className="price-total-value">
												-$
												{totalDiscount}
											</p>
										</div>
									)}
									<div className="price-total">
										<p className="price-total-title">Subtotal:</p>
										<p className="price-total-value">
											$
											{subTotal?.toLocaleString('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}
										</p>
									</div>
									<div className="price-total">
										<p className="price-total-title">Sales Tax (7.75%):</p>
										<p className="price-total-value">${taxes?.toFixed(2)}</p>
									</div>
									<div className="price-total mb-10">
										<p className="price-total-title">
											<strong>Total:</strong>
										</p>
										<p className="price-total-value">
											$
											{total?.toLocaleString('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}
										</p>
									</div>
									<hr className="mb-10" />
									<div className="price-total mt-10 ">
										<p className="price-total-title">Deposit Amount (10%):</p>
										<p className="price-total-value">
											${(total * 0.1).toFixed(2)}
										</p>
									</div>
									<div className="price-total">
										<p className="price-total-title">Due Upon Delivery:</p>
										<p className="price-total-value">
											$
											{(total - total * 0.1).toLocaleString('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}
										</p>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="tab-information">
					<div className="tab-information-block">
						<div
							className="title-block"
							onClick={(e) => toggleSection('installerTab', e)}
						>
							<h2 className="title-h2">Installer</h2>
							<button>
								{sections.installerTab ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.installerTab && (
							<>
								{/* display installer name */}
								<div className="content-block">
									<p>
										<strong>Installer:</strong>
										{orderDetails.installer?.fullName}
									</p>
									<p>
										<strong>Phone:</strong>
										{orderDetails.installer?.phoneNumber}
									</p>
									<p>
										<strong>Email:</strong>
										<a href={`mailto:${orderDetails.installer?.email}`}>
											{orderDetails.installer?.email}
										</a>
									</p>
								</div>
							</>
						)}
					</div>
				</div>
				<div className="tab-information">
					<div className="title-block" onClick={() => toggleSection('notes')}>
						<h2 className="title-h2">Order Notes</h2>
						<button>
							{sections.notes ? (
								<ChevronDown size={24} />
							) : (
								<ChevronRight size={24} />
							)}
						</button>
					</div>
					{sections.notes && (
						<div className=" content-block">
							{/* upload multiple files */}
							<div className="field-section-full formSubmitFile ">
								<form ref={formRef} onSubmit={handleSubmit(onSubmitFile)}>
									<textarea
										placeholder="Add new note"
										className="reg-textarea"
										{...register('textNote', { required: true })}
									/>
									{errors.textNote && (
										<span className="error">This field is required</span>
									)}
									{fileInputs.map((index, i) => (
										<input
											key={`${index}-${i}`}
											type="file"
											name="file"
											className="reg-input"
											onChange={(e) => {
												if (e.target.files.length > 0) {
													fileUploadHandler(e.target.files[0]);
												}
											}}
										/>
									))}

									<button type="button" onClick={removeFileInput}>
										<X size={20} className="ml-2 text-redColor-500" />
									</button>
									<button type="button" onClick={addFileInput}>
										<PlusCircle size={20} className="ml-2 text-redColor-500" />
									</button>
									{Msg && (
										<strong className="text-greenColor-500 w-full block font-light">
											{Msg}
										</strong>
									)}
									{progress.started && (
										<div className="progress-bar">
											<div
												className="progress-bar-fill"
												style={{ width: `${progress.pc}%` }}
											></div>
											<span>{progress.msg}</span>
										</div>
									)}
									<button
										type="submit"
										className="btn-save"
										// onClick={addNote}
									>
										Save Note
									</button>
								</form>
							</div>
							<div className="content-block">
								{orderDetails?.notes &&
									[...orderDetails.notes].reverse().map((note, index) => (
										<div key={index} className="notesList">
											<span className="notes-item-title">
												<strong>Date:</strong>{' '}
												{new Date(note?.createdAt).toLocaleString('en-US', {
													month: '2-digit',
													day: '2-digit',
													year: '2-digit',
													hour: '2-digit',
													minute: '2-digit',
												})}
											</span>
											<p className="notes-item-title">
												{note && note?.userId && (
													<strong>Account manager:</strong>
												)}
												{note && note?.userId && getUserName(note?.userId)}
											</p>
											<span className="whitespace-normal"></span>
											<div className="notes-item-value">
												<strong>Comments:</strong>
												<br />
												{note?.text}
											</div>
											<div className="field-section-full download-array">
												{note?.attachments?.length > 0 ? (
													<strong className="w-full">
														Download attachments
													</strong>
												) : (
													''
												)}
												{note?.attachments &&
													note.attachments.map((file, index) => (
														<div key={index} className="notes-item-attachment">
															<a
																href="#"
																onClick={(e) => {
																	e.preventDefault();
																	window.open(
																		file.url,
																		'_blank',
																		'noopener,noreferrer'
																	);
																}}
																className="btn-download-file"
															>
																<Download size={12} />
															</a>
														</div>
													))}
											</div>
										</div>
									))}
							</div>
						</div>
					)}
				</div>
				{/* <div className="installation-permits">
					<div className="instalation-permits-block">
						<div
							className="title-block"
							onClick={() => toggleSection('permits')}
						>
							<h2 className="title-h2">Installation Permits</h2>
							<button>
								{sections.permits ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.permits && (
							<>
							
								<div className="content-box">
									<p>
										Please provide any permits or other documentation required
										for installation of the building.
									</p>
									<form className="permits-form">
										<input type="file" name="permits" />
										<button type="submit" className="redBtn">
											Upload
										</button>
									</form>
								</div>
							</>
						)}
					</div>
				</div> */}
				<div className="installation-permits">
					<div className="instalation-permits-block">
						<div
							className="title-block"
							onClick={() => toggleSection('recomendations')}
						>
							<h2 className="title-h2">Order Recomendations</h2>
							<button>
								{sections.recomendations ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.recomendations && (
							<>
								<div className="content-box">
									<h2>BUILDINGS OVER 12' LEG HEIGHT</h2>
									<p>
										Buidings with a leg height over 12' may require additonal
										structural elements (ex, diagonal bracing) and additional
										labor/equipment charges. Please contact your Sales
										Representative at 530-763-4809 for assistance with these
										buildings.
									</p>

									<h2>SNOW LOAD</h2>

									<p>
										If the building will be installed in an area with moderate
										or heavy snow - additional charges may be required to handle
										a snow load. Please contact your Sales Representative at
										530-763-4809 for details.
									</p>

									<h2>SIDE ENTRIES AND HEADERS</h2>

									<p>
										Side entries, and headers needed to support the entries, may
										require additional charges to modify leg spacing or to add
										additional legs and trusses. Please contact your Sales
										Representative at 530-763-4809 for details.
									</p>

									<h2>PRICING AND OPTIONS</h2>

									<p>
										Pricing and options shown are subject to change at any time
										and may vary based upon current promotions, specials, or
										annual pricing adjustments. Current pricing will be included
										in the final quote and will require your review and approval
										prior to order.
									</p>

									<p>
										We will do all we can to ensure your complete satisfaction.
										Please contact us for questions, concerns, or custom styles
										or sizes.
									</p>
								</div>
							</>
						)}
					</div>
				</div>
				<div className="installation-permits">
					<div className="instalation-permits-block">
						<div
							className="title-block"
							onClick={() => toggleSection('invoice')}
						>
							<h2 className="title-h2">Invoice Status</h2>
							<button>
								{sections.invoice ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.invoice && (
							<>
								<div className="content-box">
									<BillingDetails order={orderDetails} />
								</div>
							</>
						)}
					</div>
				</div>

				<div className="tab-information">
					<div className="status-box-block">
						<div
							className="title-block"
							onClick={() => toggleSection('statussection')}
						>
							<h2 className="title-h2">Order Status</h2>
							<button>
								{sections.statussection ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.statussection && (
							<>
								<div className="content-box">
									<form
										onSubmit={handleSubmit(handleStatus)}
										className=" px-8 pb-8 mb-4"
									>
										<div className="mb-4">
											<label
												className="block text-gray-700 text-s font-bold mb-2"
												htmlFor="status"
											>
												Change status
											</label>
											<div className="inline-block relative max-w-xs">
												<select
													{...register('status', {
														defaultValue: orderDetails.status,
													})}
													className="block appearance-none w-full bg-white border  hover:border-gray-500 hover:cursor-pointer px-4 py-2  rounded shadow leading-tight focus:outline-none focus:shadow-outline"
												>
													<option value="1">Quote</option>
													<option value="2">Order set</option>
													<option value="3">Permits</option>
													<option value="4">Invoice</option>
													<option value="5">Installation</option>
													<option value="6">Repair</option>
													<option value="10">Done</option>
													<option value="-1">Delete</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<ChevronDown size={24} />
												</div>
											</div>
											{/* {errors.status && (
										<p className="text-redColor-500 text-s italic p-2">
											{errors.status.message}
										</p>
									)} */}
										</div>

										<button type="submit" className="redBtn">
											Change status
										</button>
									</form>
								</div>
							</>
						)}
					</div>
				</div>
				{/*  Two buttons one to send a contract and the other view in 3D design*/}
				<div className="flex flex-col md:flex-row mt-4 gap-4">
					<button className="redBtn w-1/4 mt-4 ">Send Contract</button>
					<button className="redBtn w-1/4 mt-4 " onClick={sendToDesign}>
						View in 3D Design
					</button>
				</div>
			</aside>
		</div>
	);
}
