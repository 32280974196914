import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchUserById,
	fetchUserSetting,
	fetchUsers,
} from '../slices/userSlice';
import UserDetails from '../components/users/UserDetails';
import { User, Edit3 } from 'react-feather';
import { removePhoneFormatting } from '../hooks/phoneFormat';

export default function Users() {
	const dispatch = useDispatch();
	const users = useSelector((state) => state?.auth?.users?.items); // Adjust based on your store setup
	const userById = useSelector((state) => state?.auth?.users?.userById); // Adjust based on your store setup
	// const userSetting = useSelector((state) => state?.auth?.users?.userSetting); // Adjust based on your store setup

	const [selectedUserId, setSelectedUserId] = useState(null);

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	const openDetails = (userId) => {
		setSelectedUserId(userId);
		dispatch(fetchUserById(userId));
		dispatch(fetchUserSetting(userId));
		dispatch(fetchUsers());
	};

	// Search bar
	const [filterFirstName, setFilterFirstName] = useState('');
	const [filterLastName, setFilterLastName] = useState('');
	const [filterCompanyName, setFilterCompanyName] = useState('');
	// const [filterPhone, setFilterPhone] = useState('');
	const [filterPhone, setFilterPhone] = useState('');
	const [pageSize, setPageSize] = useState(10); // Set initial page size to 10
	const [filterSection, setFilterSection] = useState({ filterOptions: true }); // Adjust based on your needs
	const [filterEmail, setFilterEmail] = useState('');

	const [currentPage, setCurrentPage] = useState(1);

	// Search filters

	const applyFilters = () => {
		const newPhone = removePhoneFormatting(filterPhone);
		dispatch(
			fetchUsers({
				pageSize: pageSize,
				page: currentPage,
				sortOrder: 'ASC',
				firstName: filterFirstName,
				lastName: filterLastName,
				phone: newPhone,
				email: filterEmail, // Add this line
			})
		);
	};

	const resetFilters = () => {
		setFilterFirstName('');
		setFilterLastName('');
		setFilterCompanyName('');

		applyFilters(); // Fetch all materials without filters
		dispatch(fetchUsers());
	};

	return (
		<>
			<div className="dashboard-content">
				{filterSection.filterOptions && (
					<div className="filters-options mt-10">
						<input
							type="text"
							placeholder="Filter by name..."
							value={filterFirstName}
							onChange={(e) => setFilterFirstName(e.target.value)}
						/>
						<input
							type="text"
							placeholder="Filter by last name..."
							value={filterLastName}
							onChange={(e) => setFilterLastName(e.target.value)}
						/>
						<input
							type="text"
							placeholder="Filter by email..."
							value={filterEmail}
							onChange={(e) => setFilterEmail(e.target.value)}
						/>

						<button onClick={applyFilters} className="filter-btn">
							Search
						</button>
						<button onClick={resetFilters} className="filter-btn">
							Reset Inputs
						</button>
					</div>
				)}
				<table className="min-w-full">
					<thead>
						<tr>
							<th>First Name</th>
							<th>Last Name</th>
							<th>Email</th>
							<th>Permission Level</th>
						</tr>
					</thead>
					<tbody>
						{users === undefined
							? ''
							: users
									.filter((user) => !user?.email.includes('_deactivate_'))
									.map((user) => (
										<tr key={user?.id} className="user-list">
											<td>
												<span className="inline-icon">
													<div className="profilePict">
														{user?.profilePicture ? (
															<span
																style={{
																	backgroundImage: `url(${user?.profilePicture})`,
																}}
															>
																{' '}
															</span>
														) : (
															<User size={24} />
														)}
													</div>
													<p>{user?.firstName}</p>
												</span>
											</td>
											<td>{user?.lastName}</td>
											<td>{user?.email}</td>
											<td>{user?.role}</td>
											<td>
												<button
													onClick={() => openDetails(user?.id)}
													className="btn-details"
												>
													User details <Edit3 size={24} />
												</button>
											</td>
										</tr>
									))}
					</tbody>
				</table>
				{selectedUserId && (
					<UserDetails
						userId={selectedUserId}
						userDetails={userById}
						onClose={() => setSelectedUserId(null)}
					/>
				)}
			</div>
		</>
	);
}
