import { useState } from 'react';

export default function AditionalOptions({
	register,
	handleFilterCategoryId,
	sideWallSidingPanels,
	additionalbracing,
	AnchorsOptions,
	panelUpgradeOptions,
}) {
	const dropDownOptions = [
		{ value: 'none', label: 'None' },
		{ value: 'gable', label: 'Gable' },
		{ value: 'singleSlope', label: 'Single Slope' },
		{ value: 'hip', label: 'Hip' },
		{ value: 'shed', label: 'Shed' },
	];

	return (
		<div className="new-order-form">
			<p className="instructions-p">
				Select the materials and options for your frame and siding panels.
			</p>

			<div className="field-section-half">
				{/* Only when is closed at one of the sides */}
				<div className="inp-col">
					<label htmlFor="sidewall">
						<strong>Side Wall Siding Panels</strong>
					</label>
					<select {...register('sidewall')} className="select-input">
						{sideWallSidingPanels.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</div>
				{/* Only when is closed at one of the ends */}
				<div className="inp-col">
					<label htmlFor="gableEndSidingPanels">
						<strong>Gable End Siding Panels</strong>
					</label>
					<select
						{...register('gableEndSidingPanels')}
						className="select-input"
					>
						{sideWallSidingPanels.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</div>
			</div>
			<hr className="mb-5" />
			<div className="field-section-half">
				<div className="inp-col">
					<label htmlFor="frameGauge">
						<strong>Frame Gauge</strong>
					</label>

					<select
						{...register('frameGauge', { onChange: handleFilterCategoryId })}
					>
						<option value="1">14-Gauge Framing</option>
						<option value="2">12-Gauge Framing</option>
					</select>
				</div>
				<div className="inp-col checkbox-button-list">
					<div className="checkbox-option check-tcolm-lg">
						<label htmlFor="foamClouser">
							<strong>Foam Closure Horizontal 12-24</strong>
						</label>
						<input type="checkbox" {...register('foamClouser')} />
					</div>
				</div>
			</div>
			<hr className="mb-5" />
			<div className="field-section-half">
				<div className="inp-col">
					<label htmlFor="aditionalBracing">
						<strong>Additional Bracing</strong>
					</label>
					<select {...register('aditionalBracing')} className="select-input">
						{additionalbracing.map((option) => (
							<option
								key={option.value}
								value={option.value}
								defaultValue={'None'}
							>
								{option.label}
							</option>
						))}
					</select>
				</div>

				<div className="inp-col">
					<label htmlFor="anchors">
						<strong>Anchors</strong>
					</label>
					<select {...register('anchors')} className="select-input">
						{AnchorsOptions?.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</div>
			</div>
			<hr className="mb-5" />

			<div className="field-section-half">
				<div className="inp-col">
					<label htmlFor="panelUpgrade">
						<strong>Panel Upgrade</strong>
					</label>
					<select {...register('panelUpgrade')} className="select-input">
						{panelUpgradeOptions?.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</div>
				<div className="inp-col checkbox-button-list">
					<div className="checkbox-option check-tcolm-lg">
						<label htmlFor="insulation">
							<strong>Insulation Material (R10 Bubble)</strong>
						</label>
						<input type="checkbox" {...register('insulation')} />
					</div>
				</div>
			</div>
			<hr className="mb-5" />
			<div className="field-section-half">
				<div className="inp-col">
					<h3 className="font-bold mb-3">Left Section Insulation</h3>
					<ul className="checkbox-button-list ">
						<li className="checkbox-option check-tcolm ">
							<label htmlFor="roofInsulationLS">Roof</label>
							<input type="checkbox" {...register('roofInsulationLS')} />
						</li>
						<li className="checkbox-option  check-tcolm">
							<label htmlFor="backInsulationLS">Back Wall</label>
							<input type="checkbox" {...register('backInsulationLS')} />
						</li>
						<li className="checkbox-option  check-tcolm">
							<label htmlFor="leftInsulationLS">Left Sidewall</label>
							<input type="checkbox" {...register('leftInsulationLS')} />
						</li>
						<li className="checkbox-option  check-tcolm">
							<label htmlFor="frontInsulationLS">Front Wall</label>
							<input type="checkbox" {...register('frontInsulationLS')} />
						</li>
					</ul>
				</div>
				<div className="inp-col">
					<h3 className="font-bold mb-3">Right Section Insulation</h3>
					<ul className="checkbox-button-list check-tcolm">
						<li className="checkbox-option  ">
							<label htmlFor="roofInsulationRS">Roof</label>
							<input type="checkbox" {...register('roofInsulationRS')} />
						</li>
						<li className="checkbox-option  check-tcolm">
							<label htmlFor="backInsulationRS">Back Wall</label>
							<input type="checkbox" {...register('backInsulationRS')} />
						</li>
						<li className="checkbox-option  check-tcolm">
							<label htmlFor="rightInsulationRS">Right Sidewall</label>
							<input type="checkbox" {...register('rightInsulationRS')} />
						</li>
						<li className="checkbox-option  check-tcolm">
							<label htmlFor="frontInsulationRS">Front Wall</label>
							<input type="checkbox" {...register('frontInsulationRS')} />
						</li>
					</ul>
				</div>
			</div>
			<hr className="mb-5" />
			<div className="field-section-half">
				<div className="inp-col">
					<h3 className="font-bold mb-3">Center Section Insulation</h3>
					<ul className="checkbox-button-list check-tcolm w-full">
						<li className="checkbox-option  ">
							<label htmlFor="roofInsulationCS">Roof</label>
							<input type="checkbox" {...register('roofInsulationCS')} />
						</li>
						<li className="checkbox-option  check-tcolm">
							<label htmlFor="backInsulationCS">Back Wall</label>
							<input type="checkbox" {...register('backInsulationCS')} />
						</li>
					</ul>
				</div>
				<div className="inp-col">
					<h3 className="font-bold mb-3">Front Section Insulation</h3>
					<ul className="checkbox-button-list check-tcolm w-full">
						<li className="checkbox-option  ">
							<label htmlFor="roofInsulationFS">Roof</label>
							<input type="checkbox" {...register('roofInsulationFS')} />
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
