import { useState } from 'react';

export default function Colors() {
	const buildingTypes = [
		{ label: 'Garage', value: 'carport' },
		{ label: 'Barn', value: 'barn' },
		{ label: 'Carport', value: 'carport' },
		{ label: 'RV Cover', value: 'rvcover' },
		{ label: 'Commercial', value: 'commercial' },
		{ label: 'Other', value: 'other' },
	];

	const [buidingTypeSelect, setBuildingTypeSelect] = useState('carport');

	//  Colors options

	const colorsRoofOptions = [
		{ label: 'White', value: 'white', colorRef: '#ffffff' },
		{ label: 'Black', value: 'black', colorRef: '#000000' },
		{ label: 'Evergreen', value: 'evergreen', colorRef: '#2a4f3c' },
		{ label: 'Quaker Gray', value: 'quakergray', colorRef: '#777973' },
		{ label: 'Clay', value: 'clay', colorRef: '#b9aa96' },
		{ label: 'Barn Red', value: 'barnred', colorRef: '#923728' },
		{ label: 'Slate Blue', value: 'slateblue', colorRef: '#527c8f' },
		{ label: 'Pebble Beige', value: 'pebblebeige', colorRef: '#e9ddbd' },
		{ label: 'Earth Brown', value: 'earthbrown', colorRef: '#473b30' },
		{ label: 'Burgundy', value: 'burgundy', colorRef: '#3c1a22' },
		{ label: 'Pewter Gray', value: 'pewtergray', colorRef: '#b0b1af' },
		{ label: 'Rawhide', value: 'rawhide', colorRef: '#ccad80' },
		{ label: 'Galvalume', value: 'galvalume', colorRef: '#808183' },
		{ label: 'Burnished Slate', value: 'burnishedslate', colorRef: '#746e5e' },
		{ label: 'Sandstone', value: 'sandstone', colorRef: '#dbc08f' },
		{ label: 'Ash Gray', value: 'ashgray', colorRef: '#dbc08f' },
		{ label: 'Royal Blue', value: 'royalblue', colorRef: '#425ba3' },
		{ label: 'Cardinal Red', value: 'cardinalred', colorRef: '#ea0025' },
	];
	const colorsTrimOptions = [
		{ label: 'White', value: 'white', colorRef: '#ffffff' },
		{ label: 'Black', value: 'black', colorRef: '#000000' },
		{ label: 'Evergreen', value: 'evergreen', colorRef: '#2a4f3c' },
		{ label: 'Quaker Gray', value: 'quakergray', colorRef: '#777973' },
		{ label: 'Clay', value: 'clay', colorRef: '#b9aa96' },
		{ label: 'Barn Red', value: 'barnred', colorRef: '#923728' },
		{ label: 'Slate Blue', value: 'slateblue', colorRef: '#527c8f' },
		{ label: 'Pebble Beige', value: 'pebblebeige', colorRef: '#e9ddbd' },
		{ label: 'Earth Brown', value: 'earthbrown', colorRef: '#473b30' },
		{ label: 'Burgundy', value: 'burgundy', colorRef: '#3c1a22' },
		{ label: 'Pewter Gray', value: 'pewtergray', colorRef: '#b0b1af' },
		{ label: 'Rawhide', value: 'rawhide', colorRef: '#ccad80' },
		{ label: 'Galvalume', value: 'galvalume', colorRef: '#808183' },
		{ label: 'Burnished Slate', value: 'burnishedslate', colorRef: '#746e5e' },
		{ label: 'Sandstone', value: 'sandstone', colorRef: '#dbc08f' },
		{ label: 'Ash Gray', value: 'ashgray', colorRef: '#dbc08f' },
		{ label: 'Royal Blue', value: 'royalblue', colorRef: '#425ba3' },
		{ label: 'Cardinal Red', value: 'cardinalred', colorRef: '#ea0025' },
	];

	const colorsGabelOptions = [
		{ label: 'White', value: 'white', colorRef: '#ffffff' },
		{ label: 'Black', value: 'black', colorRef: '#000000' },
		{ label: 'Evergreen', value: 'evergreen', colorRef: '#2a4f3c' },
		{ label: 'Quaker Gray', value: 'quakergray', colorRef: '#777973' },
		{ label: 'Clay', value: 'clay', colorRef: '#b9aa96' },
		{ label: 'Barn Red', value: 'barnred', colorRef: '#923728' },
		{ label: 'Slate Blue', value: 'slateblue', colorRef: '#527c8f' },
		{ label: 'Pebble Beige', value: 'pebblebeige', colorRef: '#e9ddbd' },
		{ label: 'Earth Brown', value: 'earthbrown', colorRef: '#473b30' },
		{ label: 'Burgundy', value: 'burgundy', colorRef: '#3c1a22' },
		{ label: 'Pewter Gray', value: 'pewtergray', colorRef: '#b0b1af' },
		{ label: 'Rawhide', value: 'rawhide', colorRef: '#ccad80' },
		{ label: 'Galvalume', value: 'galvalume', colorRef: '#808183' },
		{ label: 'Burnished Slate', value: 'burnishedslate', colorRef: '#746e5e' },
		{ label: 'Sandstone', value: 'sandstone', colorRef: '#dbc08f' },
		{ label: 'Ash Gray', value: 'ashgray', colorRef: '#dbc08f' },
		{ label: 'Royal Blue', value: 'royalblue', colorRef: '#425ba3' },
		{ label: 'Cardinal Red', value: 'cardinalred', colorRef: '#ea0025' },
	];

	const colorsSideWall = [
		{ label: 'White', value: 'white', colorRef: '#ffffff' },
		{ label: 'Black', value: 'black', colorRef: '#000000' },
		{ label: 'Evergreen', value: 'evergreen', colorRef: '#2a4f3c' },
		{ label: 'Quaker Gray', value: 'quakergray', colorRef: '#777973' },
		{ label: 'Clay', value: 'clay', colorRef: '#b9aa96' },
		{ label: 'Barn Red', value: 'barnred', colorRef: '#923728' },
		{ label: 'Slate Blue', value: 'slateblue', colorRef: '#527c8f' },
		{ label: 'Pebble Beige', value: 'pebblebeige', colorRef: '#e9ddbd' },
		{ label: 'Earth Brown', value: 'earthbrown', colorRef: '#473b30' },
		{ label: 'Burgundy', value: 'burgundy', colorRef: '#3c1a22' },
		{ label: 'Pewter Gray', value: 'pewtergray', colorRef: '#b0b1af' },
		{ label: 'Rawhide', value: 'rawhide', colorRef: '#ccad80' },
		{ label: 'Galvalume', value: 'galvalume', colorRef: '#808183' },
		{ label: 'Burnished Slate', value: 'burnishedslate', colorRef: '#746e5e' },
		{ label: 'Sandstone', value: 'sandstone', colorRef: '#dbc08f' },
		{ label: 'Ash Gray', value: 'ashgray', colorRef: '#dbc08f' },
		{ label: 'Royal Blue', value: 'royalblue', colorRef: '#425ba3' },
		{ label: 'Cardinal Red', value: 'cardinalred', colorRef: '#ea0025' },
	];

	const colorsWainscot = [
		{ label: 'None', value: 'none', colorRef: '#f8f8f8' },
		{ label: 'White', value: 'white', colorRef: '#ffffff' },
		{ label: 'Black', value: 'black', colorRef: '#000000' },
		{ label: 'Evergreen', value: 'evergreen', colorRef: '#2a4f3c' },
		{ label: 'Quaker Gray', value: 'quakergray', colorRef: '#777973' },
		{ label: 'Clay', value: 'clay', colorRef: '#b9aa96' },
		{ label: 'Barn Red', value: 'barnred', colorRef: '#923728' },
		{ label: 'Slate Blue', value: 'slateblue', colorRef: '#527c8f' },
		{ label: 'Pebble Beige', value: 'pebblebeige', colorRef: '#e9ddbd' },
		{ label: 'Earth Brown', value: 'earthbrown', colorRef: '#473b30' },
		{ label: 'Burgundy', value: 'burgundy', colorRef: '#3c1a22' },
		{ label: 'Pewter Gray', value: 'pewtergray', colorRef: '#b0b1af' },
		{ label: 'Rawhide', value: 'rawhide', colorRef: '#ccad80' },
		{ label: 'Galvalume', value: 'galvalume', colorRef: '#808183' },
		{ label: 'Burnished Slate', value: 'burnishedslate', colorRef: '#746e5e' },
		{ label: 'Sandstone', value: 'sandstone', colorRef: '#dbc08f' },
		{ label: 'Ash Gray', value: 'ashgray', colorRef: '#dbc08f' },
		{ label: 'Royal Blue', value: 'royalblue', colorRef: '#425ba3' },
		{ label: 'Cardinal Red', value: 'cardinalred', colorRef: '#ea0025' },
	];

	const colorsEndWalls = [
		{ label: 'White', value: 'white', colorRef: '#ffffff' },
		{ label: 'Black', value: 'black', colorRef: '#000000' },
		{ label: 'Evergreen', value: 'evergreen', colorRef: '#2a4f3c' },
		{ label: 'Quaker Gray', value: 'quakergray', colorRef: '#777973' },
		{ label: 'Clay', value: 'clay', colorRef: '#b9aa96' },
		{ label: 'Barn Red', value: 'barnred', colorRef: '#923728' },
		{ label: 'Slate Blue', value: 'slateblue', colorRef: '#527c8f' },
		{ label: 'Pebble Beige', value: 'pebblebeige', colorRef: '#e9ddbd' },
		{ label: 'Earth Brown', value: 'earthbrown', colorRef: '#473b30' },
		{ label: 'Burgundy', value: 'burgundy', colorRef: '#3c1a22' },
		{ label: 'Pewter Gray', value: 'pewtergray', colorRef: '#b0b1af' },
		{ label: 'Rawhide', value: 'rawhide', colorRef: '#ccad80' },
		{ label: 'Galvalume', value: 'galvalume', colorRef: '#808183' },
		{ label: 'Burnished Slate', value: 'burnishedslate', colorRef: '#746e5e' },
		{ label: 'Sandstone', value: 'sandstone', colorRef: '#dbc08f' },
		{ label: 'Ash Gray', value: 'ashgray', colorRef: '#dbc08f' },
		{ label: 'Royal Blue', value: 'royalblue', colorRef: '#425ba3' },
		{ label: 'Cardinal Red', value: 'cardinalred', colorRef: '#ea0025' },
	];

	// State
	const [selectedRoofColor, setSelectedRoofColor] = useState('white');
	const [selectedTrimColor, setSelectedTrimColor] = useState('white');
	const [selectedEndWallsColor, setSelectedEndWallsColor] = useState('white');
	const [selectedGableEndColor, setSelectedGableEndColor] = useState('white');
	const [selectedSideWallColor, setSelectedSideWallColor] = useState('white');
	const [selectedWainscotColor, setSelectedWainscotColor] = useState('white');

	// Handle color change

	const handleRoofChange = (e) => {
		setSelectedRoofColor(e.target.value);
	};
	const handleTrimChange = (e) => {
		setSelectedTrimColor(e.target.value);
	};

	const handleEndWallsChange = (e) => {
		setSelectedEndWallsColor(e.target.value);
	};

	const handleGableEndChange = (e) => {
		setSelectedGableEndColor(e.target.value);
	};

	const handleSideWallChange = (e) => {
		setSelectedSideWallColor(e.target.value);
	};

	const handleWainscotChange = (e) => {
		setSelectedWainscotColor(e.target.value);
	};

	return (
		<>
			<div className="new-order-form">
				<div className="list-color-options">
					<p className="instructions-p">
						Actual colors may vary slightly from the screen color shown.
					</p>
					<ul className="radio-button-list">
						<li>Roof</li>
						{colorsRoofOptions.map((color) => (
							<li className="radio-color-option" key={color.value}>
								<input
									type="radio"
									id={color.value}
									name="roofColor"
									value={color.value}
									checked={selectedRoofColor === color.value}
									onChange={handleRoofChange}
									style={{ backgroundColor: color.colorRef }}
								/>
								<label htmlFor={color.value}>
									{color?.label && (
										<span className="selected-value">{color.label}</span>
									)}
								</label>
							</li>
						))}
					</ul>
				</div>
				<div className="list-color-options">
					<ul className="radio-button-list">
						<li>Trim Colors</li>
						{colorsTrimOptions.map((colorT) => (
							<li className="radio-color-option" key={colorT.value}>
								<input
									type="radio"
									id={colorT.value}
									name="trimColor"
									value={colorT.value}
									checked={selectedTrimColor === colorT.value}
									onChange={handleTrimChange}
									style={{ backgroundColor: colorT.colorRef }}
								/>
								<label htmlFor={colorT.value}>
									{/* {colorT.label} */}
									{colorT?.label && (
										<span className="selected-value">{colorT?.label}</span>
									)}
								</label>
							</li>
						))}
					</ul>
				</div>
				<div className="list-color-options">
					<ul className="radio-button-list">
						<li>Endwalls Colors</li>
						{colorsEndWalls.map((colorT) => (
							<li className="radio-color-option" key={colorT.value}>
								<input
									type="radio"
									id={colorT.value}
									name="endWallsColor"
									value={colorT.value}
									checked={selectedEndWallsColor === colorT.value}
									onChange={handleEndWallsChange}
									style={{ backgroundColor: colorT.colorRef }}
								/>
								<label htmlFor={colorT.value}>
									{/* {colorT.label} */}
									{colorT?.label && (
										<span className="selected-value">{colorT?.label}</span>
									)}
								</label>
							</li>
						))}
					</ul>
				</div>
				{buidingTypeSelect === 'barn' && (
					<>
						<div className="list-color-options">
							<ul className="radio-button-list">
								<li>Gable End Siding</li>
								{colorsGabelOptions.map((colorT) => (
									<li className="radio-color-option" key={colorT.value}>
										<input
											type="radio"
											id={colorT.value}
											name="GabelEndColor"
											value={colorT.value}
											checked={selectedGableEndColor === colorT.value}
											onChange={handleGableEndChange}
											style={{ backgroundColor: colorT.colorRef }}
										/>
										<label htmlFor={colorT.value}>
											{/* {colorT.label} */}
											{colorT?.label && (
												<span className="selected-value">{colorT?.label}</span>
											)}
										</label>
									</li>
								))}
							</ul>
						</div>
						<div className="list-color-options">
							<ul className="radio-button-list">
								<li>Side Wall Siding</li>
								{colorsSideWall.map((colorT) => (
									<li className="radio-color-option" key={colorT.value}>
										<input
											type="radio"
											id={colorT.value}
											name="sideWallColor"
											value={colorT.value}
											checked={selectedSideWallColor === colorT.value}
											onChange={handleSideWallChange}
											style={{ backgroundColor: colorT.colorRef }}
										/>
										<label htmlFor={colorT.value}>
											{/* {colorT.label} */}
											{colorT?.label && (
												<span className="selected-value">{colorT?.label}</span>
											)}
										</label>
									</li>
								))}
							</ul>
						</div>
						<div className="list-color-options">
							<ul className="radio-button-list">
								<li>Wainscot</li>
								{colorsWainscot.map((colorT) => (
									<li className="radio-color-option" key={colorT.value}>
										<input
											type="radio"
											id={colorT.value}
											name="wainscotColor"
											value={colorT.value}
											checked={selectedWainscotColor === colorT.value}
											onChange={handleWainscotChange}
											style={{ backgroundColor: colorT.colorRef }}
										/>
										<label htmlFor={colorT.value}>
											{/* {colorT.label} */}
											{colorT?.label && (
												<span className="selected-value">{colorT?.label}</span>
											)}
										</label>
									</li>
								))}
							</ul>
						</div>
					</>
				)}
			</div>
		</>
	);
}
