import { ChevronDown, ChevronRight } from 'react-feather';

export default function PricePreview({
	sections,
	toggleSection,
	totalEstimate,
	discount,
	totalLegPrice,
	cSHeight,
	cSLength,
	cSWidth,
	totalRoofStylePrice,
	totalRoofPitchPrice,
	roofStyleValue,
	roofPitchOptions,
}) {
	const totalDiscount = discount ? discount : 0;
	const subTotal = totalLegPrice + totalEstimate - totalDiscount;
	const taxes = subTotal * 0.0775;
	const total = subTotal + subTotal * 0.0775;

	return (
		<div className="tab-information">
			<div className="tab-information-block">
				<div className="user-information">
					<>
						<div
							className="title-block"
							onClick={(e) => toggleSection('client', e)}
						>
							<h2 className="title-h2">Price Summary</h2>
							<button>
								{sections.client ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.client && (
							<div className="price-cont">
								<p className="price-subtitle">Structure Details</p>
								<table className="price-summary">
									<tbody>
										<tr>
											<td>
												<strong>Base Price:</strong> {cSWidth}‘x{cSLength}‘
											</td>
											<td>
												${discount ? totalEstimate - discount : totalEstimate}
											</td>
										</tr>
										<tr>
											<td>
												<strong>Installation Surface:</strong> Concrete
											</td>
											<td>-</td>
										</tr>
										<tr>
											<td>
												<strong>Roof:</strong> Rawhide
											</td>
											<td>-</td>
										</tr>
										<tr>
											<td>
												<strong>Trim Colors:</strong> White
											</td>
											<td>-</td>
										</tr>
										<tr>
											<td>
												<strong>Roof Style:</strong>
												{roofStyleValue}
											</td>
											<td>
												$
												{totalRoofStylePrice?.toLocaleString('en-US', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}
											</td>
										</tr>
										<tr>
											<td>
												<strong>Roof Pitch:</strong>
												{roofPitchOptions?.find(
													(pitch) => pitch.price === totalRoofPitchPrice
												)?.label || '3/12'}
											</td>
											<td>
												$
												{totalRoofPitchPrice?.toLocaleString('en-US', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}
											</td>
										</tr>
										<tr>
											<td>
												<strong>Roof Overhang:</strong> Standard
											</td>
											<td>-</td>
										</tr>
										<tr>
											<td>
												<strong>Trusses:</strong> Standard
											</td>
											<td>-</td>
										</tr>
										<tr>
											<td>
												<strong>Gauge:</strong> 14-Gauge Framing
											</td>
											<td>-</td>
										</tr>
										<tr>
											<td>
												<strong>Brace:</strong> Standard Brace
											</td>
											<td>-</td>
										</tr>
										{totalLegPrice ? (
											<tr>
												<td>
													<strong>Leg Height:</strong> {cSHeight}‘
												</td>
												<td>
													$
													{totalLegPrice?.toLocaleString('en-US', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
													})}
												</td>
											</tr>
										) : (
											''
										)}
									</tbody>
								</table>

								<div className="price-total-container">
									<div className="price-total">
										<p className="price-total-title">Building Estimate:</p>
										<p className="price-total-value">
											$
											{totalEstimate?.toLocaleString('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}
										</p>
									</div>
									{discount && (
										<div className="price-total">
											<p className="price-total-title">Discount:</p>
											<p className="price-total-value">
												-$
												{totalDiscount}
											</p>
										</div>
									)}
									<div className="price-total">
										<p className="price-total-title">Subtotal:</p>
										<p className="price-total-value">
											$
											{subTotal?.toLocaleString('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}
										</p>
									</div>
									<div className="price-total">
										<p className="price-total-title">Sales Tax (7.75%):</p>
										<p className="price-total-value">${taxes?.toFixed(2)}</p>
									</div>
									<div className="price-total mb-10">
										<p className="price-total-title">
											<strong>Total:</strong>
										</p>
										<p className="price-total-value">
											$
											{total?.toLocaleString('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}
										</p>
									</div>
									<hr className="mb-10" />
									<div className="price-total mt-10 ">
										<p className="price-total-title">Deposit Amount (10%):</p>
										<p className="price-total-value">
											${(total * 0.1).toFixed(2)}
										</p>
									</div>
									<div className="price-total">
										<p className="price-total-title">Due Upon Delivery:</p>
										<p className="price-total-value">
											$
											{(total - total * 0.1).toLocaleString('en-US', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}
										</p>
									</div>
								</div>
							</div>
						)}
					</>
				</div>
			</div>
		</div>
	);
}
