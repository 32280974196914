// Style options

export const styleOptions = [
	{ label: 'Carport', selectedV: "(12'-24' Wide)", value: 'carport' },
	{ label: 'Triple Wide', selectedV: "(26'-30' Wide)", value: 'tw' },
	{ label: 'Wide Span Deluxe', selectedV: '(32’-50’ Wide)', value: 'wsd' },
	{ label: 'Garage', selectedV: '', value: 'garage' },
	{ label: 'Barn', selectedV: '', value: 'barn' },
	{ label: 'Straight Line Barn', selectedV: '', value: 'slbarn' },
	{ label: 'Utility', selectedV: '', value: 'utility' },
	{ label: 'Hex Barn', selectedV: '', value: 'hexbarn' },
	{ label: 'Lean To', selectedV: '', value: 'leanto' },
	{ label: 'RV Cover', selectedV: '', value: 'rv' },
];

// Installation surface options Other

export const installationSurfaceOptions = [
	{ label: 'Other', value: 'other' },
	{ label: 'Dirt', value: 'dirt' },
	{ label: 'Gravel', value: 'gravel' },
	{ label: 'Asphalt', value: 'asphalt' },
	{ label: 'Concrete', value: 'concrete' },
];

// Roof style options
export const roofStyle = [
	{ label: 'Standard', value: 'standard', price: 0 },
	{ label: 'A-Frame Horizontal', value: 'a-frame-horizontal', price: 165 },
	{ label: 'A-Frame Vertical', value: 'a-frame-vertical', price: 820 },
];

//  Center storage options None

export const centerStorageOptions = [
	{ label: 'None', value: 'none' },
	{ label: 'Back', value: 'back' },
	{ label: 'Left Side', value: 'left' },
	{ label: 'Right Side', value: 'right' },
];

//  Left storage options None

export const leftStorageOptions = [
	{ label: 'None', value: 'none' },
	{ label: 'Back', value: 'back' },
	{ label: 'Left Side', value: 'left' },
	{ label: 'Right Side', value: 'right' },
];

//  Right storage options None

export const rightStorageOptions = [
	{ label: 'None', value: 'none' },
	{ label: 'Back', value: 'back' },
	{ label: 'Left Side', value: 'left' },
	{ label: 'Right Side', value: 'right' },
];

// Roof pitch options
export const roofPitchOptions = [
	{ label: '1/12', value: '1/12', price: 831 },
	{ label: '2/12', value: '2/12', price: 1038.75 },
	{ label: '3/12', value: '3/12', price: 0 },
	{ label: '4/12', value: '4/12', price: 831 },
	{ label: '5/12', value: '5/12', price: 1038.75 },
];

export const dropDownOptions = [
	{ value: 'gable', label: 'Gable' },
	{ value: 'singleSlope', label: 'Single Slope' },
	{ value: 'hip', label: 'Hip' },
	{ value: 'shed', label: 'Shed' },
];

export const leanOptions = [
	{ value: 'none', label: 'None' },
	{ value: 'leanToOnly', label: 'Lean to Only' },
	{ value: 'leanToWithStorage', label: 'Lean to with Storage' },
];
