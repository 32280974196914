// React imports and libraries
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

// API calls / Slices imports
import { createOrder, fetchOrders } from '../slices/ordersSlice';
import { fetchSelf, fetchUsers, fetchUserSetting } from '../slices/userSlice';
import { fetchMaterialsPrice, fetchLegsPrice } from '../slices/materialsSlice';
import CreateNewClient from './orderSections/CreateNewClient';
import {
	createClient,
	fetchClientById,
	fetchClientsOrder,
} from '../slices/clientSlice';

// Components imports
import StyleSettings from './orderSections/styleSettings';
import SizesComponent from './orderSections/SizesComponent';
import DoorWindows from './orderSections/DoorWindows';
import Colors from './orderSections/Colors';
import SideEnds from './orderSections/SideEnds';
import AditionalOptions from './orderSections/AditionalOptions';
import PricePreview from './orderSections/PricePreview';

// Utility functions, data and styles
import { formatPhoneNumber } from '../hooks/phoneFormat';
import {
	gableEndsOptions,
	sidewallOptions,
	windBracingOptions,
	jTrimOptions,
	sideWallSidingPanels,
	additionalbracing,
	AnchorsOptions,
	panelUpgradeOptions,
	leanRoofPitch,
} from '../formData/aditionalOptionsData';
import {
	styleOptions,
	centerStorageOptions,
	roofStyle,
	installationSurfaceOptions,
	roofPitchOptions,
} from '../formData/styleData';

// images and icons
import { ChevronDown, ChevronRight, List, Download } from 'react-feather';

export default function NewOrder({ onClose }) {
	const dispatch = useDispatch();
	const clientObjectStorage = localStorage.getItem('clientId');
	const clientObject = JSON.parse(clientObjectStorage);
	const clientIdStorage = clientObject?.id;
	const [existingClient, setExistingClient] = useState(true);
	// const clients = useSelector((state) => state?.clients.items.items);
	const client = useSelector((state) => state?.clients?.clientById);
	const gaugeTotal = useSelector((state) => state?.materials?.price?.items);
	const legPrice = useSelector((state) => state?.materials?.legPrice?.items);
	// Need it to get the user name as a salesman
	const usersList = useSelector((state) => state.auth?.users?.items);
	const user = useSelector((state) => state?.auth?.self?.id);
	const userCommission = useSelector(
		(state) => state?.auth?.userSetting?.metadata?.commission
	);

	// windows and doors
	const [doorWindowSelections, setDoorWindowSelections] = useState([]);
	console.log('doorWindowSelections', doorWindowSelections);

	// Base on 100 results per page
	const pageSize = 100;

	// Sections state

	const [sections, setSections] = useState({
		client: false,
		address: false,
		orderForm: false,
		styleTab: false,
		sizeTab: false,
		sidesTab: false,
		additionalsTab: false,
		doorsTab: false,
		colorsTab: false,
		warrantyTab: false,
		notesTab: false,
	});

	const toggleSection = (section, e) => {
		e.preventDefault(); // Prevent the default form submission
		setSections((prevSections) => ({
			...prevSections,
			[section]: !prevSections[section],
		}));
	};

	// if current user and userList.id is the same return the user name userlist.name
	const getUserName = (userId) => {
		if (!userId) {
			return 'Unknown';
		}

		const user = usersList?.find(
			(user) => user?.id?.trim().toLowerCase() === userId.trim().toLowerCase()
		);

		return user ? user.firstName : 'Unknown';
	};

	// Fetch clients and client by id

	useEffect(() => {
		dispatch(fetchClientsOrder());
		if (clientIdStorage || client?.firstName) {
			dispatch(fetchClientById(clientIdStorage || client.Id));
		}
		dispatch(fetchSelf());
		dispatch(fetchUserSetting(user));
	}, [dispatch]);

	// set a unique client id to select a different

	const handleExistingClient = async (e) => {
		await dispatch(fetchClientById(e));
	};

	// Create a new client
	const createNewClient = async (data) => {
		const newClient = {
			firstName: data.firstName,
			lastName: data.lastName,
			companyName: data.companyName,
			email: data.email,
			phoneNumber: data.phoneNumber,
			source: user,
			address: {
				address: data.address,
				city: data.city,
				state: data.state,
				postalCode: data.postalCode,
			},
			notes: [],
		};
		try {
			// Dispatch the createClient action and wait for the response
			const createdClient = await dispatch(createClient(newClient));

			// Log the response to debug
			console.log('Created Client Response:', createdClient);

			// Check if the createdClient has an ID
			if (createdClient && createdClient.id) {
				// Store the client ID in localStorage
				localStorage.setItem(
					'clientId',
					JSON.stringify({ id: createdClient.id })
				);

				// Fetch the client by ID after creation
				await dispatch(fetchClientById(createdClient.id));
			} else {
				console.error('Failed to create client or client ID is missing');
			}
		} catch (error) {
			console.error('Error creating client:', error);
		}
	};

	// Preload address

	const [preloadedAddress, setPreloadedAddress] = useState({
		address: '',
		city: '',
		state: '',
		postalCode: '',
	});

	useEffect(() => {
		if (client) {
			setPreloadedAddress({
				address: client.address,
				city: client.city,
				state: client.state,
				postalCode: client.postalCode,
			});
		}
	}, [client]);

	// Handle different client

	const handdelDifferentClient = () => {
		localStorage.removeItem('clientId');
		dispatch(fetchClientById(''));
	};

	const {
		register,
		control,
		handleSubmit,
		watch,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			installationSurface: 'concrete',
			roofStyle: 'standard',
			roofPitchOptions: '3/12',
			centerStorageOptions: 'none',
			// width: 0,
			// length: 0,
			// height: 6,
			address: client?.address,
			city: client?.city,
			state: client?.state,
			postalCode: client?.postalCode,
			items: [
				{
					materialId: '389',
					description: '14 Gauge Base Only',
					quantity: '1',
					// unitPrice: '',
					// metadata: {},
				},
			],
		},
	});

	// Select installer
	// fetch user data and create a list only with role of INSTALLER
	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	// Starting calculations for the order

	const [selectedStyle, setSelectestyle] = useState('carport');
	const handleOnChange = (e) => {
		setSelectestyle(e.target.value);
	};

	// center section
	const [cSWidth, setCSWidth] = useState('20');
	const [cSLength, setCSLength] = useState('30');
	const [cSHeight, setCSHeight] = useState('6');
	const [dephCentralStorage, setDephCentralStorage] = useState('none');
	// left lean-to
	const [lLWidth, setLLWidth] = useState('');
	const [lLLength, setLLLengh] = useState('');
	const [lLHeight, setLLHeight] = useState('');
	const [dephLeftStorage, setDephLeftStorage] = useState('none');

	// right lean-to
	const [rLWidth, setRLWidth] = useState('');
	const [rLLength, setRLLength] = useState('');
	const [rLHeight, setRLHeight] = useState('');
	const [dephRightStorage, setDephRightStorage] = useState('none');

	// Front lean-to
	const [fLWidth, setFLWidth] = useState('');
	const [fLLength, setFLLength] = useState('');
	const [fLHeight, setFLHeight] = useState('');
	const [dephFrontStorage, setDephFrontStorage] = useState('none');

	// Back lean-to
	const [bLWidth, setBLWidth] = useState('');
	const [bLLength, setBLLength] = useState('');
	const [bLHeight, setBLHeight] = useState('');
	const [dephBackStorage, setDephBackStorage] = useState('none');

	// State for lean-to
	const [leftLean, setLeftLean] = useState('none');
	const [rightLean, setRightLean] = useState('none');
	const [frontLean, setFrontLean] = useState('none');
	const [backLean, setBackLean] = useState('none');
	console.log('leftLean', leftLean);

	// center storage state and handdle change

	const [centerStorage, setCenterStorage] = useState('none');
	const handleCenterStorageChange = (e) => {
		setCenterStorage(e.target.value);
	};

	const installers = useSelector((state) =>
		state?.auth?.users?.items?.filter((user) => user.role === 'INSTALLER')
	);

	// call data from local storage to add to the order

	const onSubmit = async (data) => {
		const installerCommission =
			data.roofStyle === 'a-frame-vertical' ? true : false;

		const orderPayload = {
			clientId: client.id,
			installerId: data?.installerId, // replace with actual installerId
			salesmanId: user,
			isVerticalRoof: installerCommission,
			commission: userCommission, // adjust as needed
			transportationFee: 0.0, // adjust as needed
			drawingsAmount: 0.0, // adjust as needed
			equipmentAmount: 0.0, // adjust as needed
			discountAmount: data?.discount,
			installationAmount: 20.0, // adjust as needed
			reference: 0, // adjust as needed
			address: data.address,
			city: data.city,
			state: data.state,
			postalCode: data.postalCode,
			source: user,
			items: [
				...data.items.map((item) => ({
					description: item.description,
					materialId: parseInt(item.materialId, 10),
					quantity: parseInt(item.quantity, 10),
					unitPrice: parseFloat(item.unitPrice) || 0,
					metadata: {}, // Add empty metadata object
				})),
				...(lLWidth && lLLength && lLHeight
					? [
							{
								description: 'Left Lean-To',
								materialId: lLWidth, // replace with actual materialId
								quantity: lLLength, // replace with actual quantity
								unitPrice: lLHeight, // replace with actual unitPrice
								metadata: {}, // Add empty metadata object
							},
					  ]
					: []),
				...(rLWidth && rLLength && rLHeight
					? [
							{
								description: 'Right Lean-To',
								materialId: rLWidth, // replace with actual materialId
								quantity: rLLength, // replace with actual quantity
								unitPrice: rLHeight, // replace with actual unitPrice
								metadata: {}, // Add empty metadata object
							},
					  ]
					: []),
				...doorWindowSelections.map((selection) => ({
					description: selection.description,
					materialId: parseInt(selection.materialId, 10) || 'Unknown',
					quantity: parseInt(selection.quantity, 10),
					unitPrice: parseFloat(selection.defaultPrice) || 0,
					metadata: {
						locationLeft: selection.location,
						locationRight: selection.location,
						locationTop: selection.location,
						section: selection.section || 'Unknown',
						buildingSides: selection.buildingSides,
						defaultPrice: parseFloat(selection.defaultPrice) || 0,
					},
				})),
			],
			metadata: {
				installerId: data?.installerId,
				installationSurface: data.installationSurface,
				roofStyle: data.roofStyle,
				roofPitchOptions: data.roofPitchOptions,
				centerStorageOptions: centerStorage,

				// // totals
				// totalPrice: totalEstimate,
				// totalGauge: totalgauge,
				// totalLegPrice: totalLegPrice,
				// totalRoofStylePrice: totalRoofStylePrice,
			},
		};
		try {
			await dispatch(createOrder(orderPayload));
			await dispatch(fetchClientsOrder(pageSize));
			await dispatch(fetchOrders(pageSize));
			console.log('Order created:', orderPayload);
		} catch (error) {
			console.error('Error creating order:', error);
		}

		if (onClose) onClose();
	};

	// Price function search

	// Filter components
	const [filterName, setFilterName] = useState('');
	const [filterCategoryId, setFilterCategoryId] = useState('1');
	const [roofPirchTotal, setRoofPirchTotal] = useState('');

	const handleFilterCategoryId = (e) => {
		const value = e.target.value;
		console.log('Selected Frame Gauge:', value);
		setFilterCategoryId(e.target.value);
	};

	// Apply filter on change of filter values

	const calculateAndApplyFilters = async () => {
		let legCategory = 5;
		let totalPrice = 0;

		if (selectedStyle === 'wsd') {
			legCategory = 20;
		}

		// Prevent calling the API with undefined values
		if (
			!filterCategoryId ||
			!cSWidth ||
			!cSLength ||
			!legCategory ||
			!cSHeight
		) {
			console.error('All filter parameters must be set.');
			return;
		}

		// Fetch and add the price of the center section
		const centerMaterials = await dispatch(
			fetchMaterialsPrice({
				categoryId: filterCategoryId,
				width: cSWidth,
				length: cSLength,
				pageSize: 1000, // Assuming default values if not provided elsewhere
				page: 1,
				sortOrder: 'ASC',
			})
		);
		const centerLegsPrice = await dispatch(
			fetchLegsPrice({
				categoryId: legCategory,
				length: cSLength,
				height: cSHeight,
			})
		);
		totalPrice += centerMaterials?.payload?.price + centerLegsPrice; // Adjust based on the structure of your API response

		// Fetch and add the price of the left lean-to if it exists
		if (lLWidth && lLLength && lLHeight) {
			const leftMaterials = await dispatch(
				fetchMaterialsPrice({
					categoryId: filterCategoryId,
					width: lLWidth,
					length: lLLength,
					pageSize: 1000, // Assuming default values if not provided elsewhere
					page: 1,
					sortOrder: 'ASC',
				})
			);
			const leftLegsPrice = await dispatch(
				fetchLegsPrice({
					categoryId: legCategory,
					length: lLLength,
					height: lLHeight,
				})
			);
			totalPrice += leftMaterials.payload.price + leftLegsPrice; // Adjust based on the structure of your API response
		}

		// Fetch and add the price of the right lean-to if it exists
		if (rLWidth && rLLength && rLHeight) {
			const rightMaterials = await dispatch(
				fetchMaterialsPrice({
					categoryId: filterCategoryId,
					width: rLWidth,
					length: rLLength,
					pageSize: 1000, // Assuming default values if not provided elsewhere
					page: 1,
					sortOrder: 'ASC',
				})
			);
			const rightLegsPrice = await dispatch(
				fetchLegsPrice({
					categoryId: legCategory,
					length: rLLength,
					height: rLHeight,
				})
			);
			totalPrice += rightMaterials.payload.price + rightLegsPrice; // Adjust based on the structure of your API response
		}

		// Fetch and add the price of the roof if it exists
		if (roofPirchTotal) {
			const roofMaterials = await dispatch(
				fetchMaterialsPrice({
					categoryId: filterCategoryId,
					width: cSWidth,
					length: cSLength,
					pageSize: 1000, // Assuming default values if not provided elsewhere
					page: 1,
					sortOrder: 'ASC',
				})
			);
			totalPrice += roofMaterials.payload.price; // Adjust based on the structure of your API response
		}
	};

	useEffect(() => {
		calculateAndApplyFilters();
	}, [
		filterCategoryId,
		cSWidth,
		cSLength,
		cSHeight,
		lLWidth,
		lLLength,
		lLHeight,
		rLWidth,
		rLLength,
		rLHeight,
	]);

	// calculate the total price

	const totalgauge = gaugeTotal?.reduce(
		(acc, item) => acc + item.defaultPrice,
		0
	);

	const totalLegPrice = legPrice && legPrice[0] ? legPrice[0].defaultPrice : 0;

	// Helper function to get the roof style price
	const getRoofStylePrice = (roofStyleValue) => {
		if (roofStyleValue === 'standard') {
			return 0;
		}
		const selectedRoofStyle = roofStyle.find(
			(style) => style.value === roofStyleValue
		);
		return selectedRoofStyle ? selectedRoofStyle.price : 0;
	};

	const getRoofPitchPrice = (roofPitchValue) => {
		const selectedRoofPitch = roofPitchOptions.find(
			(pitch) => pitch.value === roofPitchValue
		);
		return selectedRoofPitch ? selectedRoofPitch.price : 0;
	};

	const totalRoofStylePrice = getRoofStylePrice(watch('roofStyle'));
	const totalRoofPitchPrice = getRoofPitchPrice(watch('roofPitchOptions'));

	const totalEstimate =
		totalgauge + totalLegPrice + totalRoofStylePrice + totalRoofPitchPrice;

	return (
		<div className="new-order-page">
			<aside className="new-details-panel">
				<div className="details-number-quote">
					<h2 className="details-number-title">Create a New Order</h2>
					<div className="block-right">
						Estimated{' '}
						<strong className="ml-2 mr-4 text-redColor-500">
							{(totalEstimate || 0).toLocaleString('en-US', {
								style: 'currency',
								currency: 'USD',
							})}
						</strong>
						<button onClick={onClose} className="btn-tab-selection">
							Cancel quote
						</button>
					</div>
				</div>
				{client?.id ? (
					<>
						<div className="action-btns-container">
							<div className="action-btns">
								<button
									className="btn-tab-selection"
									onClick={() => handdelDifferentClient()}
								>
									Different Client <List size={24} />
								</button>
							</div>
						</div>
						<div className="tab-information margin-top">
							<div className="tab-information-block">
								<div className="user-information">
									<>
										<div
											className="title-block"
											onClick={(e) => toggleSection('client', e)}
										>
											<h2 className="title-h2">Client Information</h2>
											<button>
												{sections.client ? (
													<ChevronDown size={24} />
												) : (
													<ChevronRight size={24} />
												)}
											</button>
										</div>
										{sections.client && (
											<>
												<div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2 content-block">
													<div className="data-content">
														<p>
															<strong>First Name</strong> {client?.firstName}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>Last Name</strong> {client?.lastName}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>Email</strong> {client?.email}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>Phone</strong>{' '}
															{client?.phoneNumber
																? formatPhoneNumber(client?.phoneNumber)
																: 'N/A'}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>Street</strong> {client?.address}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>City</strong> {client?.city}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>State</strong> {client?.state}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>Zipcode</strong> {client?.postalCode}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>User Created </strong>{' '}
															{new Date(client.createdAt).toLocaleString(
																'en-US',
																{
																	month: '2-digit',
																	day: '2-digit',
																	year: '2-digit',
																	hour: '2-digit',
																	minute: '2-digit',
																}
															)}
														</p>
													</div>
												</div>
											</>
										)}
									</>
								</div>
							</div>
						</div>
						{/* Notes */}
						<div className="tab-information">
							<div className="tab-information-block">
								<div
									className="title-block"
									onClick={(e) => toggleSection('notesTab', e)}
								>
									<h2 className="title-h2">Client Notes</h2>
									<button>
										{sections.notesTab ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.notesTab && (
									<>
										<div className="new-order-form">
											<div className="field-section-full">
												{client?.notes &&
													client?.notes.map((note, index) => (
														<div key={index} className="notesList">
															<span className="notes-item-title">
																<strong>Date:</strong>{' '}
																{new Date(note?.createdAt).toLocaleString(
																	'en-US',
																	{
																		month: '2-digit',
																		day: '2-digit',
																		year: '2-digit',
																		hour: '2-digit',
																		minute: '2-digit',
																	}
																)}
															</span>
															<p className="notes-item-title">
																{note && note?.userId && (
																	<strong>Account manager:</strong>
																)}
																{note &&
																	note?.userId &&
																	getUserName(note?.userId)}
															</p>
															<span className="whitespace-normal"></span>
															<div className="notes-item-value">
																<strong>Comments:</strong>
																<br />
																{note?.text}
															</div>
															<div className="field-section-full download-array">
																{note?.attachments?.length > 0 ? (
																	<strong className="w-full">
																		Download attachments
																	</strong>
																) : (
																	''
																)}
																{note?.attachments &&
																	note.attachments.map((file, index) => (
																		<div
																			key={index}
																			className="notes-item-attachment"
																		>
																			<a
																				href="#"
																				onClick={(e) => {
																					e.preventDefault();
																					window.open(
																						file.url,
																						'_blank',
																						'noopener,noreferrer'
																					);
																				}}
																				className="btn-download-file"
																			>
																				<Download size={12} />
																			</a>
																		</div>
																	))}
															</div>
														</div>
													))}
											</div>
										</div>
									</>
								)}
							</div>
						</div>
					</>
				) : (
					<CreateNewClient
						createNewClient={createNewClient}
						setExistingClient={setExistingClient}
						handleExistingClient={handleExistingClient}
						clientIdStorage={clientIdStorage}
						existingClient={existingClient}
						sections={sections}
						toggleSection={toggleSection}
						setSections={setSections}
					/>
				)}

				{client?.firstName && (
					<form onSubmit={handleSubmit(onSubmit)}>
						{/* Installation addres input */}
						<div className="tab-information">
							<div className="tab-information-block">
								<div
									className="title-block"
									onClick={(e) => toggleSection('address', e)}
								>
									<h2 className="title-h2">Installation Address</h2>

									<button>
										{sections.address ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.address && (
									<>
										<div className="new-order-form">
											<p className="instructions-p">
												Please enter the address where the building will be
												installed.
											</p>

											<div className="field-section-half">
												<div className="inp-col">
													<label htmlFor="address">
														<strong>Address</strong>
													</label>
													<input
														{...register('address', {
															required: 'Address is required',
														})}
														value={preloadedAddress.address}
													/>
													{errors.address && (
														<p className="text-redColor-500 text-s italic p-2">
															{errors?.address.message}
														</p>
													)}
												</div>
												<div className="inp-col">
													<label htmlFor="city">
														<strong>City</strong>
													</label>
													<input
														{...register('city', {
															required: 'City is required',
														})}
														value={preloadedAddress.city}
													/>
													{errors.city && (
														<p className="text-redColor-500 text-s italic p-2">
															{errors?.city.message}
														</p>
													)}
												</div>
												<div className="inp-col">
													<label htmlFor="state">
														<strong>State</strong>
													</label>
													<input
														{...register('state', {
															required: 'State is required',
														})}
														value={preloadedAddress.state}
													/>
													{errors.state && (
														<p className="text-redColor-500 text-s italic p-2">
															{errors?.state.message}
														</p>
													)}
												</div>
												<div className="inp-col">
													<label htmlFor="postalCode">
														<strong>Postal Code</strong>
													</label>
													<input
														{...register('postalCode', {
															required: 'Postal Code is required',
														})}
														value={preloadedAddress.postalCode}
													/>
													{errors.postalCode && (
														<p className="text-redColor-500 text-s italic p-2">
															{errors?.postalCode.message}
														</p>
													)}
												</div>
											</div>
										</div>
										<button
											onClick={(e) => {
												e.preventDefault();
												setPreloadedAddress({
													address: '',
													city: '',
													state: '',
													postalCode: '',
												});
												reset({
													address: '',
													city: '',
													state: '',
													postalCode: '',
												});
											}}
											className="btn-tab-selection"
										>
											Different Address
										</button>
									</>
								)}
							</div>
						</div>
						<div className="tab-information">
							<div className="tab-information-block">
								<div
									className="title-block"
									onClick={(e) => toggleSection('installerTab', e)}
								>
									<h2 className="title-h2">Installer</h2>
									<button>
										{sections.installerTab ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.installerTab && (
									<>
										<div className="new-order-form">
											<div className="field-section-full">
												<div className="inp-col installer-dropdown">
													<label htmlFor="installerId">
														<strong>Assign an installer</strong>
													</label>
													<select {...register('installerId')}>
														<option value="">Select installer</option>
														{installers?.map((installer) => (
															<option key={installer.id} value={installer.id}>
																{installer?.firstName} {installer?.lastName}
															</option>
														))}
													</select>
												</div>
											</div>
										</div>
									</>
								)}
							</div>
						</div>

						<div className="tab-information">
							<div className="tab-information-block">
								<div
									className="title-block"
									onClick={(e) => toggleSection('styleTab', e)}
								>
									<h2 className="title-h2">Style</h2>
									<button>
										{sections.styleTab ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.styleTab && (
									<>
										<div className="new-order-form">
											<h2 className="tab-subtitle">Bulding type</h2>
											<p className="instructions-p">
												Choose a design from the options below to begin.
												Afterward, navigate through this and additional tabs to
												personalize your structure with ease.
											</p>
											<h3 className="mb-3 font-bold ">Style</h3>
											<div className="list-options">
												<ul className="radio-button-list">
													{styleOptions.map((option) => (
														<li className="radio-option" key={option.value}>
															<input
																type="radio"
																id={option.value}
																name="option"
																value={option.value}
																checked={selectedStyle === option.value}
																onChange={handleOnChange}
															/>
															<label htmlFor={option.value}>
																{option.label}
																{option?.selectedV && (
																	<span className="selected-value">
																		{option.selectedV}
																	</span>
																)}
															</label>
														</li>
													))}
												</ul>
											</div>
											<hr className="mb-5" />
											<div className="field-section-half">
												<div className="inp-col">
													<label htmlFor="installationSurface">
														<strong>Installation Surface</strong>
													</label>
													<select
														{...register('installationSurface', {
															defaultValue: 'concrete',
														})}
														className="select-input"
													>
														{installationSurfaceOptions.map((option) => (
															<option key={option.value} value={option.value}>
																{option.label}
															</option>
														))}
													</select>
												</div>

												<div className="inp-col">
													<label htmlFor="roofStyle">
														<strong>Roof style</strong>
													</label>
													<select
														{...register('roofStyle')}
														className="select-input"
													>
														{roofStyle.map((option) => (
															<option
																key={option.value}
																value={option.value}
																defaultValue={'Standard'}
															>
																{option.label}
															</option>
														))}
													</select>
												</div>
											</div>
											<hr className="mb-5" />
											<div className="field-section-half">
												<div className="inp-col">
													<label htmlFor="roofPitchOptions">
														<strong>Roof pitch</strong>
													</label>
													<select
														{...register('roofPitchOptions', {
															defaultValue: '3/12',
														})}
														className="select-input"
													>
														{roofPitchOptions.map((option) => (
															<option
																key={option.value}
																value={option.value}
																defaultValue={'3/12'}
															>
																{option.label}
															</option>
														))}
													</select>
												</div>

												<div className="inp-col">
													<label htmlFor="centerStorageOptions">
														<strong>Center Storage</strong>
													</label>
													<select
														onChange={handleCenterStorageChange}
														className="select-input"
													>
														{centerStorageOptions.map((option) => (
															<option
																key={option.value}
																value={option.value}
																defaultValue={'None'}
															>
																{option.label}
															</option>
														))}
													</select>
												</div>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
						{/* Size */}
						<SizesComponent
							toggleSection={toggleSection}
							selectedStyle={selectedStyle}
							sections={sections}
							setCSWidth={setCSWidth}
							setCSLength={setCSLength}
							setCSHeight={setCSHeight}
							cSWidth={cSWidth}
							cSLength={cSLength}
							cSHeight={cSHeight}
							register={register}
							control={control}
							handleFilterCategoryId={handleFilterCategoryId}
							setLLHeight={setLLHeight}
							lLHeight={lLHeight}
							setLLWidth={setLLWidth}
							lLWidth={lLWidth}
							setLLLengh={setLLLengh}
							lLLength={lLLength}
							setRLHeight={setRLHeight}
							rLHeight={rLHeight}
							setRLWidth={setRLWidth}
							rLWidth={rLWidth}
							setRLLength={setRLLength}
							rLLength={rLLength}
							centerStorage={centerStorage}
							setDephCentralStorage={setDephCentralStorage}
							dephCentralStorage={dephCentralStorage}
							setDephLeftStorage={setDephLeftStorage}
							dephLeftStorage={dephLeftStorage}
							setDephRightStorage={setDephRightStorage}
							dephRightStorage={dephRightStorage}
							setDephFrontStorage={setDephFrontStorage}
							dephFrontStorage={dephFrontStorage}
							setDephBackStorage={setDephBackStorage}
							dephBackStorage={dephBackStorage}
							setRoofPirchTotal={setRoofPirchTotal}
							backLean={backLean}
							frontLean={frontLean}
							leftLean={leftLean}
							rightLean={rightLean}
						/>

						{/* Sides and Ends */}
						<div className="tab-information">
							<div className="tab-information-block">
								<div
									className="title-block"
									onClick={(e) => toggleSection('sideTab', e)}
								>
									<h2 className="title-h2">Sides & ends</h2>
									<button>
										{sections.sideTab ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.sideTab && (
									<SideEnds
										setLeftLean={setLeftLean}
										setRightLean={setRightLean}
										setFrontLean={setFrontLean}
										setBackLean={setBackLean}
										leftLean={leftLean}
										rightLean={rightLean}
										frontLean={frontLean}
										backLean={backLean}
										gableEndsOptions={gableEndsOptions}
										sidewallOptions={sidewallOptions}
										windBracingOptions={windBracingOptions}
										jTrimOptions={jTrimOptions}
										leanRoofPitch={leanRoofPitch}
									/>
								)}
							</div>
						</div>
						{/* Aditionals */}
						<div className="tab-information">
							<div className="tab-information-block">
								<div
									className="title-block"
									onClick={(e) => toggleSection('aditionalsTab', e)}
								>
									<h2 className="title-h2">Additional options</h2>
									<button>
										{sections.aditionalsTab ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.aditionalsTab && (
									<AditionalOptions
										control={control}
										register={register}
										handleFilterCategoryId={handleFilterCategoryId}
										sideWallSidingPanels={sideWallSidingPanels}
										additionalbracing={additionalbracing}
										AnchorsOptions={AnchorsOptions}
										panelUpgradeOptions={panelUpgradeOptions}
									/>
								)}
							</div>
						</div>
						{/* Doors */}
						<div className="tab-information">
							<div className="tab-information-block">
								<div
									className="title-block"
									onClick={(e) => toggleSection('doorsTab', e)}
								>
									<h2 className="title-h2">Doors, windows, & frameouts</h2>
									<button>
										{sections.doorsTab ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.doorsTab && (
									<DoorWindows onSelectionsChange={setDoorWindowSelections} />
								)}
							</div>
						</div>
						{/* Colors */}
						<div className="tab-information">
							<div className="tab-information-block">
								<div
									className="title-block"
									onClick={(e) => toggleSection('colorsTab', e)}
								>
									<h2 className="title-h2">Colors</h2>
									<button>
										{sections.colorsTab ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.colorsTab && <Colors />}
							</div>
						</div>
						{/* Service and warranty */}
						<div className="tab-information">
							<div className="tab-information-block">
								<div
									className="title-block"
									onClick={(e) => toggleSection('warrantyTab', e)}
								>
									<h2 className="title-h2">Services & Warranty </h2>
									<button>
										{sections.warrantyTab ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.warrantyTab && (
									<>
										<div className="new-order-form">
											<div className="field-section-full">
												<h3 className="font-bold mb-5">
													Important information
												</h3>
												<div className="w-full">
													<h3 className="font-bold mb-5">Services</h3>
													<ul className="checkbox-button-list w-full">
														<li className="checkbox-option w-full ">
															<input
																type="checkbox"
																{...register('installationFees')}
															/>
															<label htmlFor="installationFees">
																Installation Fees
															</label>
														</li>
													</ul>
												</div>
											</div>
											<div className="field-section-full">
												<h3 className="mb-3 font-bold">Warranty Information</h3>

												<ul className="w-full list-disc pl-5">
													<li>Engineer Certified</li>
													<li>1-Year Workmanship</li>
													<li>20-Year Rust Through (only on 12 gauge steel)</li>
												</ul>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
						{/* Notes */}
						<div className="tab-information">
							<div className="tab-information-block">
								<div
									className="title-block"
									onClick={(e) => toggleSection('notesTab', e)}
								>
									<h2 className="title-h2"> Discounts</h2>
									<button>
										{sections.notesTab ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.notesTab && (
									<>
										<div className="new-order-form">
											<div className="field-section-full">
												<div className="inp-col">
													<label htmlFor="discount">
														<strong>Discount</strong>
													</label>
													<input
														type="number"
														{...register('discount')}
														className="select-input"
														step="0.01"
													/>
												</div>
											</div>
											{/* <div className="field-section-full">
												{client?.notes.map((note) => (
													<div key={note.id} className="notes-block ">
														<h3 className="notes-date">
															{new Date(note.createdAt).toLocaleString(
																'en-US',
																{
																	month: '2-digit',
																	day: '2-digit',
																	year: '2-digit',
																	hour: '2-digit',
																	minute: '2-digit',
																}
															)}
														</h3>
														<div className="notes-content">
															<p>
																{typeof note.text === 'object'
																	? JSON.stringify(note.text)
																	: note.text}
															</p>
														</div>
													</div>
												))}
											</div> */}
										</div>
									</>
								)}
							</div>
						</div>
						{/* Price sumary */}
						<PricePreview
							sections={sections}
							toggleSection={toggleSection}
							totalEstimate={totalEstimate}
							discount={watch('discount')}
							totalLegPrice={totalLegPrice}
							cSHeight={cSHeight}
							cSLength={cSLength}
							cSWidth={cSWidth}
							roofStyleValue={watch('roofStyle')}
							totalRoofStylePrice={totalRoofStylePrice}
							totalRoofPitchPrice={totalRoofPitchPrice}
						/>
						<StyleSettings
							selectedStyle={selectedStyle}
							setCSWidth={setCSWidth}
							setCSLength={setCSLength}
							setCSHeight={setCSHeight}
							setLLWidth={setLLWidth}
							setLLLengh={setLLLengh}
							setLLHeight={setLLHeight}
							setRLWidth={setRLWidth}
							setRLLength={setRLLength}
							setRLHeight={setRLHeight}
						/>

						<div className="btns-footer">
							<button type="submit" className="redBtn mr-4">
								Submit Order
							</button>

							{/* <button
								className="redBtn"
								onClick={() =>
									window.open('https://www.design.bncaccess.com', '_blank')
								}
							>
								3D Design Preview
							</button> */}
						</div>
					</form>
				)}
			</aside>
		</div>
	);
}
