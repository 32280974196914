// React imports and libraries
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';

// API calls / Slices imports
import { fetchMaterialsPrice } from '../../slices/materialsSlice';

// Utility functions, data and styles
import { dropDownOptions, leanOptions } from '../../formData/styleData';

// images and icons
import leftLeanToIcon from '../../assets/left-lean-to.svg';
import centerSectionIcon from '../../assets/center-section.svg';
import backLeanToIcon from '../../assets/back-lean-to.svg';
import frontLeanToIcon from '../../assets/front-lean-to.svg';
import rightLeanToIcon from '../../assets/right-lean-to.svg';

export default function SideEnds({
	setLeftLean,
	setRightLean,
	setFrontLean,
	setBackLean,
	gableEndsOptions,
	sidewallOptions,
	windBracingOptions,
	jTrimOptions,
	leanRoofPitch,
}) {
	const dispatch = useDispatch();
	// Sides and ends tabs
	const [activeTabSide, setActiveTabSide] = useState('centerSectionSide');
	const [centerSectionCost, setCenterSectionCost] = useState(0);
	const [leanToCosts, setLeanToCosts] = useState({
		front: 0,
		back: 0,
		left: 0,
		right: 0,
	});

	// UseForm
	const { control, register, watch } = useForm({
		defaultValues: {
			frontLeanTo: [],
			leftLeanTo: [],
			centerSection: [],
			backLeanTo: [],
			rightLeanTo: [],
		},
	});

	const { fields: frontLeanToFields, append: appendFrontLeanTo } =
		useFieldArray({
			control,
			name: 'frontLeanToSide',
		});
	const { fields: backLeanToFields, append: appendBackLeanTo } = useFieldArray({
		control,
		name: 'backLeanToSide',
	});
	const { fields: leftLeanToFields, append: appendLeftLeanTo } = useFieldArray({
		control,
		name: 'leftLeanToSide',
	});

	const { fields: centerSectionFields, append: appendCenterSection } =
		useFieldArray({
			control,
			name: 'centerSectionSide',
		});

	const { fields: rightLeanToFields, append: appendRightLeanTo } =
		useFieldArray({
			control,
			name: 'rightLeanToSide',
		});

	// Handle tab click
	const handleTabClickSide = (tabNameSide) => {
		setActiveTabSide(tabNameSide);
	};

	const calculateCost = async (section) => {
		const width = watch(`${section}.width`);
		const length = watch(`${section}.length`);
		const height = watch(`${section}.height`);
		if (width && length && height) {
			const response = await dispatch(
				fetchMaterialsPrice({
					categoryId: section,
					width,
					length,
					height,
				})
			);
			return response.payload.reduce((acc, item) => acc + item.defaultPrice, 0);
		}
		return 0;
	};

	useEffect(() => {
		const updateCosts = async () => {
			const centerCost = await calculateCost('centerSectionSide');
			const frontCost = await calculateCost('frontLeanToSide');
			const backCost = await calculateCost('backLeanToSide');
			const leftCost = await calculateCost('leftLeanToSide');
			const rightCost = await calculateCost('rightLeanToSide');

			setCenterSectionCost(centerCost);
			setLeanToCosts({
				front: frontCost,
				back: backCost,
				left: leftCost,
				right: rightCost,
			});
		};

		updateCosts();
	}, [
		watch('centerSectionSide'),
		watch('frontLeanToSide'),
		watch('backLeanToSide'),
		watch('leftLeanToSide'),
		watch('rightLeanToSide'),
	]);

	return (
		<>
			<div className="new-order-form">
				<p className="instructions-p">
					<strong>Section</strong> Select the panel options for the sidewll and
					endwalls of your building.
				</p>
				<div className="doors-windows-section">
					<ul className="tab-buttons">
						<li
							className={
								activeTabSide === 'leftLeanToSide'
									? 'tab-button active'
									: 'tab-button'
							}
							onClick={(j) => handleTabClickSide('leftLeanToSide', j)}
						>
							<img src={leftLeanToIcon} alt="Icon" className="icon-dwtab" />
							<strong>Left Lean-to</strong>
						</li>
						<li
							className={
								activeTabSide === 'frontLeanToSide'
									? 'tab-button active'
									: 'tab-button'
							}
							onClick={(j) => handleTabClickSide('frontLeanToSide', j)}
						>
							<img src={frontLeanToIcon} alt="Icon" className="icon-dwtab" />
							<strong>Front Lean-to</strong>
						</li>
						<li
							className={
								activeTabSide === 'centerSectionSide'
									? 'tab-button active'
									: 'tab-button'
							}
							onClick={(j) => handleTabClickSide('centerSectionSide', j)}
						>
							<img src={centerSectionIcon} alt="Icon" className="icon-dwtab" />
							<strong>Center Section</strong>
						</li>
						<li
							className={
								activeTabSide === 'backLeanToSide'
									? 'tab-button active'
									: 'tab-button'
							}
							onClick={(j) => handleTabClickSide('backLeanToSide', j)}
						>
							<img src={backLeanToIcon} alt="Icon" className="icon-dwtab" />
							<strong>Back Lean To</strong>
						</li>
						<li
							className={
								activeTabSide === 'rightLeanToSide'
									? 'tab-button active'
									: 'tab-button'
							}
							onClick={(j) => handleTabClickSide('rightLeanToSide', j)}
						>
							<img src={rightLeanToIcon} alt="Icon" className="icon-dwtab" />
							<strong>Right Lean-to</strong>
						</li>
					</ul>

					{activeTabSide === 'frontLeanToSide' && (
						<>
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="frontLean">
										<strong>Front Lean</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontLean`)}
										className="select-input"
										onChange={(e) => setFrontLean(e.target.value)}
									>
										{leanOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
								<div className="inp-col">
									<label htmlFor="frontLeanRoofPitch">
										<strong>Front Lean Roof Pitch</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontLeanRoofPitch`)}
										className="select-input"
									>
										{leanRoofPitch.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<hr className="mb-5" />
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="frontGableEnd">
										<strong>Front Gable End</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontGableEnd`)}
										className="select-input"
									>
										{gableEndsOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
								<div className="inp-col">
									<label htmlFor="leftSidewall">
										<strong>Left sidewall</strong>
									</label>
									<select
										{...register(`${activeTabSide}.leftSidewall`)}
										className="select-input"
									>
										{sidewallOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<hr className="mb-5" />
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="backGableEnd">
										<strong>Back Gable End</strong>
									</label>
									<select
										{...register(`${activeTabSide}.backGableEnd`)}
										className="select-input"
									>
										{gableEndsOptions.map((option) => (
											<option
												key={option.value}
												value={option.value}
												defaultValue={'None'}
											>
												{option.label}
											</option>
										))}
									</select>
								</div>

								<div className="inp-col">
									<label htmlFor="windBracing">
										<strong>Wind Bracing</strong>
									</label>
									<select
										{...register(`${activeTabSide}.windBracing`)}
										className="select-input"
									>
										{windBracingOptions?.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<hr className="mb-5" />

							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="jTrim">
										<strong>J-Trim</strong>
									</label>
									<select
										{...register(`${activeTabSide}.jTrim`)}
										className="select-input"
									>
										{jTrimOptions?.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
						</>
					)}
					{activeTabSide === 'leftLeanToSide' && (
						<>
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="frontLean">
										<strong>Left Lean</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontLean`)}
										className="select-input"
										onChange={(e) => setLeftLean(e.target.value)}
									>
										{leanOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
								<div className="inp-col">
									<label htmlFor="frontLeanRoofPitch">
										<strong>Left Lean Roof Pitch</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontLeanRoofPitch`)}
										className="select-input"
									>
										{leanRoofPitch.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<hr className="mb-5" />
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="frontGableEnd">
										<strong>Front Gable End</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontGableEnd`)}
										className="select-input"
									>
										{gableEndsOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
								<div className="inp-col">
									<label htmlFor="leftSidewall">
										<strong>Left sidewall</strong>
									</label>
									<select
										{...register(`${activeTabSide}.leftSidewall`)}
										className="select-input"
									>
										{sidewallOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<hr className="mb-5" />
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="backGableEnd">
										<strong>Back Gable End</strong>
									</label>
									<select
										{...register(`${activeTabSide}.backGableEnd`)}
										className="select-input"
									>
										{gableEndsOptions.map((option) => (
											<option
												key={option.value}
												value={option.value}
												defaultValue={'None'}
											>
												{option.label}
											</option>
										))}
									</select>
								</div>

								<div className="inp-col">
									<label htmlFor="windBracing">
										<strong>Wind Bracing</strong>
									</label>
									<select
										{...register(`${activeTabSide}.windBracing`)}
										className="select-input"
									>
										{windBracingOptions?.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<hr className="mb-5" />

							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="jTrim">
										<strong>J-Trim</strong>
									</label>
									<select
										{...register(`${activeTabSide}.jTrim`)}
										className="select-input"
									>
										{jTrimOptions?.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
						</>
					)}
					{activeTabSide === 'centerSectionSide' && (
						<>
							{/* <div className="field-section-half"> */}
							{/* <div className="inp-col"> */}
							{/* <label htmlFor="frontLean">
										<strong>Center Section</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontLean`)}
										className="select-input"
									>
										{leanOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select> */}
							{/* </div> */}
							{/* <div className="inp-col">
									<label htmlFor="frontLeanRoofPitch">
										<strong>Front Lean Roof Pitch</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontLeanRoofPitch`)}
										className="select-input"
									>
										<option value="none">None</option>
										{dropDownOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div> */}
							{/* </div> */}
							{/* <hr className="mb-5" /> */}
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="frontGableEnd">
										<strong>Front Gable End</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontGableEnd`)}
										className="select-input"
									>
										{gableEndsOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
								<div className="inp-col">
									<label htmlFor="leftSidewall">
										<strong>Left sidewall</strong>
									</label>
									<select
										{...register(`${activeTabSide}.leftSidewall`)}
										className="select-input"
									>
										{sidewallOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<hr className="mb-5" />
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="backGableEnd">
										<strong>Back Gable End</strong>
									</label>
									<select
										{...register(`${activeTabSide}.backGableEnd`)}
										className="select-input"
									>
										{gableEndsOptions.map((option) => (
											<option
												key={option.value}
												value={option.value}
												defaultValue={'Open'}
											>
												{option.label}
											</option>
										))}
									</select>
								</div>
								<div className="inp-col">
									<label htmlFor="rightSidewall">
										<strong>Right sidewall</strong>
									</label>
									<select
										{...register(`${activeTabSide}.rightSidewall`)}
										className="select-input"
									>
										{sidewallOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<hr className="mb-5" />
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="windBracing">
										<strong>Wind Bracing</strong>
									</label>
									<select
										{...register(`${activeTabSide}.windBracing`)}
										className="select-input"
									>
										{windBracingOptions?.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>

								<div className="inp-col">
									<label htmlFor="jTrim">
										<strong>J-Trim</strong>
									</label>
									<select
										{...register(`${activeTabSide}.jTrim`)}
										className="select-input"
									>
										{jTrimOptions?.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
						</>
					)}
					{activeTabSide === 'backLeanToSide' && (
						<>
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="frontLean">
										<strong>Back Lean</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontLean`)}
										className="select-input"
										onChange={(e) => setBackLean(e.target.value)}
									>
										{leanOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
								<div className="inp-col">
									<label htmlFor="frontLeanRoofPitch">
										<strong>Back Lean Roof Pitch</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontLeanRoofPitch`)}
										className="select-input"
									>
										{leanRoofPitch.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<hr className="mb-5" />
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="frontGableEnd">
										<strong>Front Gable End</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontGableEnd`)}
										className="select-input"
									>
										{gableEndsOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
								<div className="inp-col">
									<label htmlFor="leftSidewall">
										<strong>Left sidewall</strong>
									</label>
									<select
										{...register(`${activeTabSide}.leftSidewall`)}
										className="select-input"
									>
										{sidewallOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<hr className="mb-5" />
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="backGableEnd">
										<strong>Back Gable End</strong>
									</label>
									<select
										{...register(`${activeTabSide}.backGableEnd`)}
										className="select-input"
									>
										{gableEndsOptions.map((option) => (
											<option
												key={option.value}
												value={option.value}
												defaultValue={'None'}
											>
												{option.label}
											</option>
										))}
									</select>
								</div>

								<div className="inp-col">
									<label htmlFor="windBracing">
										<strong>Wind Bracing</strong>
									</label>
									<select
										{...register(`${activeTabSide}.windBracing`)}
										className="select-input"
									>
										{windBracingOptions?.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<hr className="mb-5" />

							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="jTrim">
										<strong>J-Trim</strong>
									</label>
									<select
										{...register(`${activeTabSide}.jTrim`)}
										className="select-input"
									>
										{jTrimOptions?.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
						</>
					)}
					{activeTabSide === 'rightLeanToSide' && (
						<>
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="frontLean">
										<strong>Right Lean</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontLean`)}
										className="select-input"
										onChange={(e) => setRightLean(e.target.value)}
									>
										{leanOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
								<div className="inp-col">
									<label htmlFor="frontLeanRoofPitch">
										<strong>Right Lean Roof Pitch</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontLeanRoofPitch`)}
										className="select-input"
									>
										{leanRoofPitch.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<hr className="mb-5" />
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="frontGableEnd">
										<strong>Front Gable End</strong>
									</label>
									<select
										{...register(`${activeTabSide}.frontGableEnd`)}
										className="select-input"
									>
										{gableEndsOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
								<div className="inp-col">
									<label htmlFor="leftSidewall">
										<strong>Left sidewall</strong>
									</label>
									<select
										{...register(`${activeTabSide}.leftSidewall`)}
										className="select-input"
									>
										{sidewallOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<hr className="mb-5" />
							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="backGableEnd">
										<strong>Back Gable End</strong>
									</label>
									<select
										{...register(`${activeTabSide}.backGableEnd`)}
										className="select-input"
									>
										{gableEndsOptions.map((option) => (
											<option
												key={option.value}
												value={option.value}
												defaultValue={'None'}
											>
												{option.label}
											</option>
										))}
									</select>
								</div>

								<div className="inp-col">
									<label htmlFor="windBracing">
										<strong>Wind Bracing</strong>
									</label>
									<select
										{...register(`${activeTabSide}.windBracing`)}
										className="select-input"
									>
										{windBracingOptions?.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<hr className="mb-5" />

							<div className="field-section-half">
								<div className="inp-col">
									<label htmlFor="jTrim">
										<strong>J-Trim</strong>
									</label>
									<select
										{...register(`${activeTabSide}.jTrim`)}
										className="select-input"
									>
										{jTrimOptions?.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}
