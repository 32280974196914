import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bar, Line, Doughnut, Pie } from 'react-chartjs-2';
import { fetchClients } from '../slices/clientSlice';
import { fetchOrders } from '../slices/ordersSlice';

// Register the category scale

import {
	Chart,
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	ArcElement,
} from 'chart.js';
import ClientsDashboard from '../pages/ClientsDashboard';

Chart.register(
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	ArcElement
);

export default function SummaryBtns() {
	const dispatch = useDispatch();
	const clients = useSelector((state) => state.clients.items.items);
	const orders = useSelector((state) => state.orders.items);
	const selfPermissions = useSelector((state) => state?.auth?.self?.role);
	console.log('selfPermissions', selfPermissions);

	useEffect(() => {
		dispatch(fetchClients({ pageSize: 5 }));
		dispatch(fetchOrders({ pageSize: 1000 }));
	}, [dispatch]);

	// Create the data for the charts

	// clients status reference New (1) Contacted (2) Confirmed (3)
	const clientsByStatus = clients?.reduce((acc, client) => {
		const { status } = client;
		if (!acc[status]) {
			acc[status] = [];
		}
		acc[status].push(client);
		return acc;
	}, {});

	if (!clientsByStatus) {
		return <p>You do not have permission to see this data.</p>;
	}

	// client status reference New (1) Contacted (2) Confirmed (3)

	const dataStatus = {
		labels: ['Pending', 'Active', 'Blocked'],
		datasets: [
			{
				data: [
					clientsByStatus[1]?.length || 0,
					clientsByStatus[2]?.length || 0,
					clientsByStatus[3]?.length || 0,
				],
				backgroundColor: [
					'rgba(255, 255, 0, 0.4)',
					'rgba(3, 255, 0, 0.4)',
					'rgba(255, 0, 0, 0.4)',
				],
				hoverBackgroundColor: [
					'rgba(255, 255, 0, 1)',
					'rgba(3, 255, 0, 1)',
					'rgba(255, 0, 0, 1)',
				],
			},
		],
	};

	// the data coming from the orders slice has a status please count the status for each orderand this is the reference Quote (1) Changed (2) Ready (3) NotReady (4) Cancel (5) Repair (6) Completed(10)
	const ordersByStatus = orders?.reduce((acc, order) => {
		const { status } = order;
		if (!acc[status]) {
			acc[status] = [];
		}
		acc[status].push(order);
		return acc;
	}, {});

	const quotesData = {
		labels: [
			'Quote',
			'Changed',
			'Ready',
			'NotReady',
			'Cancel',
			'Repair',
			'Completed',
		],
		datasets: [
			{
				label: 'Orders',
				data: [
					ordersByStatus[1]?.length || 0,
					ordersByStatus[2]?.length || 0,
					ordersByStatus[3]?.length || 0,
					ordersByStatus[4]?.length || 0,
					ordersByStatus[5]?.length || 0,
					ordersByStatus[6]?.length || 0,
					ordersByStatus[10]?.length || 0,
				],
				backgroundColor: [
					'rgba(75,192,192,0.4)',
					'rgba(153, 102, 255, 0.4)',
					'rgba(255, 0, 0, 0.4)',
					'rgba(255, 99, 132, 0.4)',
					'rgba(54, 162, 235, 0.4)',
					'rgba(255, 205, 86, 0.4)',
					'rgba(128, 128, 128, 0.4)',
				],
				borderColor: 'rgba(255,255,255,1)',
				borderWidth: 1,
			},
		],
	};

	// create data for chart with months and sales and keept the same colors
	const salesData = {
		labels: [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		],
		datasets: [
			{
				label: 'Sales',
				data: [12, 19, 3, 5, 2, 3, 4, 5, 6, 7, 8, 9],
				backgroundColor: [
					'rgba(75,192,192,0.4)',
					'rgba(153, 102, 255, 0.4)',
					'rgba(255, 0, 0, 0.4)',
					'rgba(255, 99, 132, 0.4)',
					'rgba(54, 162, 235, 0.4)',
					'rgba(255, 205, 86, 0.4)',
					'rgba(128, 128, 128, 0.4)',
					'rgba(75,192,192,0.4)',
					'rgba(153, 102, 255, 0.4)',
					'rgba(255, 0, 0, 0.4)',
					'rgba(255, 99, 132, 0.4)',
					'rgba(54, 162, 235, 0.4)',
				],
				borderColor: 'rgba(75,192,192,1)',
				borderWidth: 1,
			},
		],
	};

	return (
		<>
			<div className="chart-container">
				<h2>Sales (No real data)</h2>
				<Line
					data={salesData}
					options={{
						title: {
							display: true,
							fontSize: 20,
						},
						legend: {
							display: true,
							position: 'right',
						},
					}}
				/>
			</div>

			<div className="chart-container">
				<h2>Clientes</h2>
				<Bar
					data={dataStatus}
					options={{
						title: {
							display: true,
							text: 'Clients by Status',
							fontSize: 20,
						},
						legend: {
							display: true,
							position: 'center',
						},
					}}
				/>
			</div>

			<div className="order-status-bar">
				<ul className="order-status-list">
					<h2 className="order-title">Orders Status</h2>
					<Bar data={quotesData} />

					{quotesData.labels.map((label, index) => (
						<li key={index}>
							<div className="quote-item">
								<span className="quote-label">{label}</span>
								<span className="quote-value">
									{quotesData.datasets[0].data[index]}
								</span>
							</div>
						</li>
					))}
				</ul>
				{selfPermissions === 'SUPER' || selfPermissions === 'ADMIN' ? (
					<ClientsDashboard />
				) : (
					''
				)}
			</div>
		</>
	);
}
