import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderById, fetchOrders } from '../slices/ordersSlice';
import { fetchWorkOrderById, fetchWorkOrders } from '../slices/workOrdersSlice';
import { fetchClientById } from '../slices/clientSlice';
import { fetchSelf } from '../slices/userSlice';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
// import SummaryBtns from '../components/SummaryBtns';
import { Search, Sliders, File, List } from 'react-feather';
import OrderDetails from '../components/OrderDetails';
import Checklist from '../components/Checklist';

export default function Orders() {
	const dispatch = useDispatch();
	const orders = useSelector((state) => state.orders.items);
	const currentUser = useSelector((state) => state.auth.self);
	const workOrders = useSelector((state) => state.workOrders.items);
	const status = useSelector((state) => state.orders.status);
	const error = useSelector((state) => state.orders.error);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [selectDetials, setSelectDetails] = useState(false);

	// Search bar
	const [filterFirstName, setFilterFirstName] = useState('');
	const [filterLastName, setFilterLastName] = useState('');
	const [filterSource, setFilterSource] = useState('');

	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	// Toggle sections
	const [filterSection, setFilterSections] = useState({
		filterOptions: true,
	});

	useEffect(() => {
		dispatch(fetchOrders());
		dispatch(fetchSelf());
		dispatch(fetchWorkOrders());
	}, [dispatch]);
	console.log('orders', workOrders);

	if (status === 'loading') {
		return <div>Loading...</div>;
	}

	if (status === 'failed') {
		return <div>Error: {error}</div>;
	}

	const openDetails = (orderId, clientId) => {
		setSelectedOrder(orderId);
		dispatch(fetchOrderById(orderId));
		dispatch(fetchClientById(clientId));
	};

	const openCheckList = (orderId) => {
		// setSelectDetails(workOrders);
		dispatch(fetchWorkOrderById(orderId));
	};

	const statusMapping = {
		1: 'Quote',
		2: 'Order set',
		3: 'Permits',
		4: 'Invoice sent',
		5: 'Installation',
		10: 'Done',
		6: 'Repair',
	};

	const applyFilters = () => {
		dispatch(
			fetchOrders({
				pageSize: pageSize,
				page: currentPage,
				sortOrder: 'ASC',
				clientFirstName: filterFirstName,
				clientLastName: filterLastName,
				email: filterSource,
			})
		);
		console.log('filterSource', filterSource);
	};

	const resetFilters = () => {
		setFilterFirstName('');
		setFilterLastName('');
		setFilterSource('');

		applyFilters(); // Fetch all materials without filters
		dispatch(fetchOrders());
	};

	const toggleSection = (filterSection) => {
		setFilterSections((prevSections) => ({
			...prevSections,
			[filterSection]: !prevSections[filterSection],
		}));
	};

	return (
		<div className="flex flex-col h-screen dashboard-page">
			<Header />
			<div className="flex flex-1 overflow-hidden">
				<Sidebar />
				<main className="flex-1 overflow-auto p-8">
					<h1 className="text-3xl text-black pb-6 mt-6">Orders Managment </h1>
					{/* <SummaryBtns /> */}
					{/* <div className="searchbar">
						<div className="filters">
							<button>
								All filters <Sliders size={24} />
							</button>
						</div>
						<div className="search-input">
							<Search size={16} />
							<input type="text" placeholder="Search" />
						</div>
					</div> */}
					<div className="searchbar">
						<div className="filters">
							<button onClick={() => toggleSection('filterOptions')}>
								All filters <Sliders size={24} />
							</button>
						</div>
					</div>
					{/* Filters options going here */}
					{filterSection.filterOptions && (
						<div className="filters-options mt-10">
							<input
								type="text"
								placeholder="Filter by name..."
								value={filterFirstName}
								onChange={(e) => setFilterFirstName(e.target.value)}
							/>
							<input
								type="text"
								placeholder="Filter by last name..."
								value={filterLastName}
								onChange={(e) => setFilterLastName(e.target.value)}
							/>

							<button onClick={applyFilters} className="filter-btn">
								Search
							</button>
							<button onClick={resetFilters} className="filter-btn">
								Reset Inputs
							</button>
						</div>
					)}
					<div className="dashboard-content">
						<h1 className="text-3xl text-black pb-6 mt-6">Order Managment </h1>
						<table className="min-w-full">
							<thead>
								<tr>
									<th>Name</th>
									<th>Date</th>
									<th>Order ID</th>
									<th>Status</th>
									<th>Address</th>
									<th>Workorder</th>
									<th>Details</th>
								</tr>
							</thead>
							<tbody>
								{/* filer for order > status 1  */}
								{orders
									.filter(
										(order) =>
											(currentUser.role === 'SUPER' ||
											currentUser.role === 'ADMIN'
												? order.status !== 1
												: order.salesmanId === currentUser.id &&
												  order.status !== 1) && order.status !== -1
									)
									.map((order) => (
										<tr key={order.id} className="select-by-name">
											<td
												onClick={() => {
													openDetails(order.id, order.clientId);
												}}
											>
												{order.clientName}
											</td>
											<td onClick={() => openDetails(order.id)}>
												{new Date(order.createdAt).toLocaleDateString()}
											</td>
											<td onClick={() => openDetails(order.id)}>
												{' '}
												{order.reference}
											</td>
											<td onClick={() => openDetails(order.id)}>
												{statusMapping[order.status]}
											</td>
											<td onClick={() => openDetails(order.id)}>
												{order.clientEmail}
											</td>
											<td>
												<button
													onClick={() => openCheckList(order.id)}
													// onClick={() => openDetails(order.id, order.clientId)}
												>
													<List size={24} />
												</button>
											</td>
											<td>
												<button
													// onClick={() => openCheckList(workOrders)}
													onClick={() => openDetails(order.id, order.clientId)}
													// className="btn-details"
												>
													<File size={24} />
												</button>
											</td>
										</tr>
									))}
							</tbody>
						</table>
						{selectedOrder && (
							<OrderDetails
								order={selectedOrder}
								onClose={() => setSelectedOrder(null)}
							/>
						)}
						{selectDetials && (
							<Checklist
								workOrders={selectDetials}
								onClose={() => setSelectDetails(null)}
							/>
						)}
					</div>
				</main>
			</div>
		</div>
	);
}
