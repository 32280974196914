

import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit';
import api from '../config/api'; 


interface ErrorType {
    message: string; 
  }

  interface CreatePaymentPayload {
    paidAmount: number;
    dueAmount: number;
    paymentDate: string;
    paymentMethod: string;
    orderId: string;
    status: number;
  }


  export const createPayment = createAsyncThunk(
    'client/createPayment',
    async (paymentData: CreatePaymentPayload, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
        const headers = {
            Authorization: `Bearer ${token}`
            };
        const response = await api.post('/invoices', paymentData, { headers });
        return response.data;
        }
        catch (error: any) {
        return rejectWithValue(error.response.data);
        }
    }
    );



  interface UpdatePaymentPayload {
    id: string;
    paidAmount: number;
    dueAmount: number;
    paymentDate: string;
    paymentMethod: string;
    orderId: string;
    status: number;
  }

  export const updatePayment = createAsyncThunk(
    'users/updatePayment',
    async (paymentData: UpdatePaymentPayload, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/invoices/${paymentData.id}`, paymentData, { headers });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );


  const billingSlice = createSlice({
    name: 'billing',
    initialState: {
        payment: null,
        error: null,
        errorMessage: null,
        status: 'idle'
    },
    reducers: {},
    extraReducers: {
        [createPayment.pending.type]: (state) => {
            state.status = 'loading';
        },
        [createPayment.fulfilled.type]: (state, action) => {
            state.status = 'succeeded';
            state.payment = action.payload;
            state.errorMessage = null;
        },
        [createPayment.rejected.type]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
            state.errorMessage = action.payload.message || 'There was an error trying to create the invoice.';
        },
        [updatePayment.pending.type]: (state) => {
            state.status = 'loading';
        },
        [updatePayment.fulfilled.type]: (state, action) => {
            state.status = 'succeeded';
            state.payment = action.payload;
            state.errorMessage = null;
        },
        [updatePayment.rejected.type]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
            state.errorMessage = action.payload.message || 'There was an error trying to update the invoice.';
        }
    }
});

export default billingSlice.reducer;


