// Sides & Ends section following options

// Front and Back options
export const gableEndsOptions = [
	{ label: 'Open', value: 'open' },
	{ label: 'Fully Enclosed', value: 'fullyEnclosed' },
	{ label: 'Gable End', value: 'gableEnd' },
	{ label: 'Extended Gable End', value: 'extendedGableEnd' },
	{ label: 'Gable End Wall 6', value: 'gableEndWall6' },
	{ label: 'Left Side Enclosed', value: 'leftSideEnclosed' },
	{ label: 'Right Side enclosed', value: 'rightSideEnclosed' },
	{ label: 'Left Side With Gable End', value: 'leftSideWithGableEnd' },
	{ label: 'Right Side with Gable End', value: 'rightSideWithGableEnd' },
];

// Left and Right options until top20Panel

export const sidewallOptions = [
	{ label: 'Open', value: 'open' },
	{ label: 'Fully Enclosed', value: 'fullyEnclosed' },
	{ label: 'Top - 3 Panel', value: 'top3Panel' },
	{ label: 'Top - 4 Panel', value: 'top4Panel' },
	{ label: 'Top - 5 Panel', value: 'top5Panel' },
	{ label: 'Top - 6 Panel', value: 'top6Panel' },
	{ label: 'Top - 7 Panel', value: 'top7Panel' },
	{ label: 'Top - 8 Panel', value: 'top8Panel' },
	{ label: 'Top - 9 Panel', value: 'top9Panel' },
	{ label: 'Top - 10 Panel', value: 'top10Panel' },
	{ label: 'Top - 11 Panel', value: 'top11Panel' },
	{ label: 'Top - 12 Panel', value: 'top12Panel' },
	{ label: 'Top - 13 Panel', value: 'top13Panel' },
	{ label: 'Top - 14 Panel', value: 'top14Panel' },
	{ label: 'Top - 15 Panel', value: 'top15Panel' },
	{ label: 'Top - 16 Panel', value: 'top16Panel' },
	{ label: 'Top - 17 Panel', value: 'top17Panel' },
	{ label: 'Top - 18 Panel', value: 'top18Panel' },
	{ label: 'Top - 19 Panel', value: 'top19Panel' },
	{ label: 'Top - 20 Panel', value: 'top20Panel' },
];

// Wind bracing options

export const windBracingOptions = [
	{ label: 'None', value: 'none' },
	{ label: 'Wind Brace', value: 'windBrace' },
];

// J-trim  options

export const jTrimOptions = [
	{ label: 'None on Sides', value: 'none' },
	{ label: 'Add J-Trim', value: 'addJTrim' },
];

//  Aditional options for the order

export const sideWallSidingPanels = [
	{ label: 'Horizontal', value: 'horizontal' },
	{ label: 'Vertical', value: 'vertical' },
];

export const additionalbracing = [
	// values from None to 20
	{ label: 'None', value: 'none' },
	{ label: '1', value: '1' },
	{ label: '2', value: '2' },
	{ label: '3', value: '3' },
	{ label: '4', value: '4' },
	{ label: '5', value: '5' },
	{ label: '6', value: '6' },
	{ label: '7', value: '7' },
	{ label: '8', value: '8' },
	{ label: '9', value: '9' },
	{ label: '10', value: '10' },
	{ label: '11', value: '11' },
	{ label: '12', value: '12' },
	{ label: '13', value: '13' },
	{ label: '14', value: '14' },
	{ label: '15', value: '15' },
	{ label: '16', value: '16' },
	{ label: '17', value: '17' },
	{ label: '18', value: '18' },
	{ label: '19', value: '19' },
	{ label: '20', value: '20' },
];

export const AnchorsOptions = [
	// from none to 40
	{ label: 'None', value: 'none' },
	{ label: '1', value: '1' },
	{ label: '2', value: '2' },
	{ label: '3', value: '3' },
	{ label: '4', value: '4' },
	{ label: '5', value: '5' },
	{ label: '6', value: '6' },
	{ label: '7', value: '7' },
	{ label: '8', value: '8' },
	{ label: '9', value: '9' },
	{ label: '10', value: '10' },
	{ label: '11', value: '11' },
	{ label: '12', value: '12' },
	{ label: '13', value: '13' },
	{ label: '14', value: '14' },
	{ label: '15', value: '15' },
	{ label: '16', value: '16' },
	{ label: '17', value: '17' },
	{ label: '18', value: '18' },
	{ label: '19', value: '19' },
	{ label: '20', value: '20' },
	{ label: '21', value: '21' },
	{ label: '22', value: '22' },
	{ label: '23', value: '23' },
	{ label: '24', value: '24' },
	{ label: '25', value: '25' },
	{ label: '26', value: '26' },
	{ label: '27', value: '27' },
	{ label: '28', value: '28' },
	{ label: '29', value: '29' },
	{ label: '30', value: '30' },
	{ label: '31', value: '31' },
	{ label: '32', value: '32' },
	{ label: '33', value: '33' },
	{ label: '34', value: '34' },
	{ label: '35', value: '35' },
	{ label: '36', value: '36' },
	{ label: '37', value: '37' },
	{ label: '38', value: '38' },
	{ label: '39', value: '39' },
	{ label: '40', value: '40' },
];

export const panelUpgradeOptions = [
	{ label: 'Standard Panel 29 Gauge', value: 'standardPanel29Gauge' },
	{ label: '26 Gauge Panel Upgrade', value: '26GaugePanelUpgrade' },
	{ label: 'Colored Screws', value: 'coloredScrews' },
];

export const leanRoofPitch = [
	{ label: '1/12', value: '1/12' },
	{ label: '2/12', value: '2/12' },
	{ label: '3/12', value: '3/12' },
];
