import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { Search, Sliders } from 'react-feather';

export default function Billing() {
	return (
		<div className="flex flex-col h-screen dashboard-page">
			<Header />
			<div className="flex flex-1 overflow-hidden">
				<Sidebar />
				<main className="flex-1 overflow-auto p-8">
					<div className="searchbar">
						<div className="filters">
							<button>
								All filters <Sliders size={24} />
							</button>
						</div>
						<div className="search-input">
							<Search size={16} />
							<input type="text" placeholder="Search" />
						</div>
					</div>
					<div className="dashboard-content">
						<h1 className="text-3xl text-black pb-6 mt-6">Billing</h1>

						<table className="min-w-full">
							<thead>
								<tr>
									<th>Invoice Number</th>
									<th>Client</th>
									<th>Amount</th>
									<th>Due Date</th>
									<th>Status</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>BNC-0001</td>
									<td>John Doe</td>
									<td>$1000.00</td>
									<td>2021-09-01</td>
									<td>Pending</td>
									<td>
										<button>View / </button>
										<button> Edit</button>
									</td>
								</tr>
								<tr>
									<td>BNC-0002</td>
									<td>Jane Doe</td>
									<td>$1500.00</td>
									<td>2021-09-01</td>
									<td>Paid</td>
									<td>
										<button>View / </button>
										<button> Edit</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</main>
			</div>
		</div>
	);
}
