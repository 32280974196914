import { useState, useRef, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
	updateClient,
	updateClientNotes,
	fetchClients,
	fetchClientById,
	deleteClient,
} from '../../slices/clientSlice';
import { fetchSelf, fetchUsers } from '../../slices/userSlice';
import { useForm } from 'react-hook-form';
import {
	ChevronDown,
	ChevronRight,
	Edit2,
	Trash2,
	Download,
	PlusCircle,
	X,
} from 'react-feather';
import axios from 'axios';
import usePopup from '../../hooks/popuphook';

export default function ClientDetails({ onClose, selectedClient, closeSlide }) {
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.auth.self);
	const usersList = useSelector((state) => state.auth?.users?.items);

	// if current user and userList.id is the same return the user name userlist.name
	const getUserName = (userId) => {
		if (!userId) {
			return 'Unknown';
		}

		const user = usersList?.find(
			(user) => user?.id?.trim().toLowerCase() === userId.trim().toLowerCase()
		);

		return user ? user.firstName : 'Unknown';
	};

	// popup
	const { isOpen, openPopup, closePopup } = usePopup();
	const [editFrom, setEditForm] = useState(false);
	const api = process.env.REACT_APP_API_URL;
	const formRef = useRef();
	const [sections, setSections] = useState({
		info: false,
		address: false,
		notes: false,
	});
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		formState: { errors },
	} = useForm();

	const pageSize = 100;

	// toggle secctions
	const toggleSection = (section) => {
		setSections((prevSections) => ({
			...prevSections,
			[section]: !prevSections[section],
		}));
	};

	// clean up the code
	const client = useSelector((state) => state.clients.clientById);
	// option for status
	const statusOptions = [
		{ value: 1, label: 'Ok', color: 'green-500' },
		{ value: 2, label: 'Waiting', color: 'yellow-500' },
		{ value: 3, label: 'Blocked', color: 'red-500' },
	];

	// Status display
	const getStatusLabel = (status) => {
		const option = statusOptions.find((option) => option.value === status);
		return option ? option.label : 'Unknown';
	};

	const getStatusColor = (status) => {
		const option = statusOptions.find((option) => option.value === status);
		return option ? option.color : 'gray-500';
	};

	// account manager
	const accountManager = usersList
		?.filter((user) => !user?.email.includes('_deactivate_'))
		.map((user) => ({
			// label: user.firstName + ' ' + user.lastName,
			value: user.id,
			label: user.firstName + ' ' + user.lastName,
		}));

	// read the users and set the account manager value to the user id and label to the user name from

	//  add more fileds to upload file
	const [fileInputs, setFileInputs] = useState([0]);
	// sending files
	const existingNotes = client?.notes || [];
	// here is the file url
	const [urlState, setUrlState] = useState([]);
	const [newNoteText, setNewNoteText] = useState('');
	// Uploading progress bar
	const [Msg, setMsg] = useState(null);
	const [progress, setProgress] = useState({ started: false, pc: 0, msg: '' });

	// pendding to clean up

	// clean functions

	// upload and remove inputs
	const addFileInput = () => {
		setFileInputs([...fileInputs, fileInputs.length]);
	};
	const removeFileInput = () => {
		if (fileInputs.length > 1) {
			const newFileInputs = fileInputs.slice(0, -1);
			setFileInputs(newFileInputs);
		}
	};

	// upload file
	const fileUploadHandler = async (file) => {
		const token = localStorage.getItem('token');

		// upload file by file and wait for the response to copy the url
		const formData = new FormData();
		if (file) {
			// Create a new File object with the same file data but a modified name
			const newFile = new File([file], file.name.replace(/\s/g, '_'), {
				type: file.type,
			});

			formData.append('file', newFile);

			setMsg('Uploading...');
			setProgress({ started: true, pc: 0, msg: 'Uploading...' });
		}

		try {
			const response = await axios.post(`${api}/files/upload`, formData, {
				onUploadProgress: (progressEvent) => {
					const pc = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					setProgress({ started: true, pc, msg: 'Uploading...' });
				},
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${token}`,
				},
			});

			// console.log('response', response.data.fileUrl);
			setUrlState([...urlState, response.data.fileUrl]);
			setMsg('File uploaded successfully!');
			setProgress({
				started: false,
				pc: 0,
				msg: 'File uploaded successfully ready to send or add more files!',
			});
		} catch (error) {
			setMsg('Error uploading file!');
		}
	};

	const onSubmitFile = async (data) => {
		// Check if newText is not empty
		const newNote = {
			userId: currentUser.id,
			createdAt: new Date().toISOString(),
			text: data.textNote,
			attachments: urlState.map((url) => ({
				description: 'Attachment',
				url: url,
			})),
		};

		// Add the new note to the existing notes
		const newNotes = [...existingNotes, newNote];

		// dispatch the action to update the notes
		await dispatch(
			updateClientNotes({
				id: selectedClient,
				notes: newNotes,
			})
		);
		// Clear the input fields
		dispatch(fetchClients(pageSize));
		setNewNoteText('');
		setUrlState([]);

		reset({
			textNote: '',
		});
		// Clear file inputs
		setFileInputs([0]);

		// Reset the form to clear the file input
		// Reset the form to clear the file input
		if (formRef.current) {
			formRef.current.reset();
		}

		dispatch(fetchClientById(selectedClient));
	};

	const onDeleteSubmit = (data) => {
		openPopup(data.id);
	};

	// delete client
	const handleDelete = async (id) => {
		console.log('delete', id);
		await dispatch(deleteClient(id));
		dispatch(fetchClients(pageSize));
		localStorage.removeItem('clientId');
		closePopup();
		onClose();
	};

	const watchedPhoneNumber = watch('phoneNumber');

	// Phone number formatting
	useEffect(() => {
		dispatch(fetchUsers({ pageSize: 100 }));
		dispatch(fetchSelf());
		const formattedPhoneNumber = formatPhoneNumber(watchedPhoneNumber);
		setPhoneNumber(formattedPhoneNumber);
	}, [watchedPhoneNumber]);

	const formatPhoneNumber = (input) => {
		const cleaned = ('' + input).replace(/\D/g, '');
		const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			const intlCode = match[1] ? '+1 ' : '';
			return intlCode + '(' + match[2] + ') ' + match[3] + '-' + match[4];
		}
		return input;
	};

	const [phoneNumber, setPhoneNumber] = useState(client?.phoneNumber || '');

	const [isSaving, setIsSaving] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [isSaved, setIsSaved] = useState(false);

	// update client info
	const onSubmit = async (data) => {
		setIsSaving(false);
		setHasError(false);
		setIsSaved(false);

		const cleanedPhoneNumber = (phoneNumber || '').replace(/\D/g, '');
		try {
			await dispatch(
				updateClient({
					id: selectedClient,
					firstName: data.firstName,
					lastName: data.lastName,
					companyName: data.companyName,
					email: data.email,
					phoneNumber: cleanedPhoneNumber,
					source: data.source,
					address: {
						address: data.address,
						city: data.city,
						state: data.state,
						postalCode: data.postalCode,
					},
					status: Number(data.status),
					active: true,
				})
			);

			dispatch(fetchClientById(selectedClient));
			dispatch(fetchClients({ pageSize: 100 }));
			setNewNoteText('');

			// Also clear the file inputs
			setFileInputs([0]);
			setIsSaved(true);
		} catch (error) {
			setHasError(true);
		} finally {
			setIsSaving(false);
		}

		// onClose();
	};

	return (
		<div className="details-details-panel">
			{isOpen && (
				<div className="modal">
					<div className="modal-content">
						<h2>Are you sure you want to delete this user?</h2>
						<div className="btncontainer">
							<button
								onClick={() => handleDelete(client?.id)}
								className="yesBtn"
							>
								Yes
							</button>
							<button onClick={closePopup} className="noBtn">
								No
							</button>
						</div>
					</div>
				</div>
			)}
			<aside className="details-panel">
				<div className="details-number">
					<h2 className="text-2xl font-semibold ">Client details</h2>

					<div className="block-right">
						<form onSubmit={handleSubmit(onDeleteSubmit)}>
							<input type="hidden" {...register('id')} value={client?.id} />

							<button type="submit" className="btn-delete-item">
								Delete Client <Trash2 size={20} />
							</button>
						</form>
						<button
							onClick={() => {
								setEditForm(!editFrom);
								setNewNoteText(''); // Clear the note textarea
							}}
							className="btn-edit-item"
						>
							{!editFrom ? (
								<>
									Edit client <Edit2 size={20} />
								</>
							) : (
								<>Cancel</>
							)}
						</button>
						<button onClick={closeSlide} className="close-btn">
							<X size={24} />
						</button>
						<div className="flex w-full items-center mt-5 ">
							<span className="text-sm font-semibold">Status:</span>
							<span className={`ml-2 text-${getStatusColor(client?.status)}`}>
								{getStatusLabel(client?.status)}
							</span>
							<span
								className={`ml-2 w-3 h-3 bg-${getStatusColor(
									client?.status
								)} rounded-full inline-block`}
							></span>
						</div>
					</div>
				</div>
				{editFrom ? (
					<>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="tab-information">
								<div className="content-block">
									<div className="field-section-full">
										<label className="block text-gray-700 text-sm font-bold mb-2">
											First Name
										</label>
										<input
											type="text"
											id="firstName"
											{...register('firstName')}
											defaultValue={client?.firstName}
											className="reg-input"
										/>
										{errors.firstName && (
											<span className="error">This field is required</span>
										)}
									</div>

									<hr className="mb-5" />
									<div className="field-section-full">
										<label
											className="block text-gray-700 text-sm font-bold mb-2"
											htmlFor="lastName"
										>
											Last Name
										</label>
										<input
											className="reg-input"
											type="text"
											id="lastName"
											{...register('lastName')}
											defaultValue={client?.lastName}
										/>
										{errors.lastName && (
											<span className="error">This field is required</span>
										)}
									</div>
									<hr className="mb-5" />
									<div className="field-section-full">
										<label
											className="block text-gray-700 text-sm font-bold mb-2"
											htmlFor="companyName"
										>
											Company Name
										</label>
										<input
											className="reg-input"
											type="text"
											id="companyName"
											{...register('companyName')}
											defaultValue={client?.companyName}
										/>
										{errors.companyName && (
											<span className="error">This field is required</span>
										)}
									</div>
									<hr className="mb-5" />

									<div className="field-section-full">
										<label
											className="block text-gray-700 text-sm font-bold mb-2"
											htmlFor="email"
										>
											Email
										</label>
										<input
											className="reg-input"
											type="email"
											id="email"
											{...register('email')}
											defaultValue={client?.email}
										/>
										{errors.email && (
											<span className="error">This field is required</span>
										)}
									</div>
									<hr className="mb-5" />
									<div className="field-section-full">
										<label
											className="block text-gray-700 text-sm font-bold mb-2"
											htmlFor="phoneNumber"
										>
											Phone Number
										</label>
										<input
											className="reg-input"
											id="phoneNumber"
											type="tel"
											defaultValue={formatPhoneNumber(client?.phoneNumber)} // Use the state-managed phoneNumber
											onChange={(e) => setPhoneNumber(e.target.value)} // Update state on change
											onBlur={(e) =>
												setPhoneNumber(formatPhoneNumber(e.target.value))
											} // Format and update state on blur
										/>
									</div>
									<hr className="mb-5" />
									<div className="field-section-full">
										<label
											className="block text-gray-700 text-sm font-bold mb-2"
											htmlFor="address"
										>
											Address
										</label>
										<input
											className="reg-input"
											type="text"
											id="address"
											{...register('address')}
											defaultValue={client?.address}
										/>
										{errors.address && (
											<span className="error">This field is required</span>
										)}
									</div>
									<div className="field-section-full">
										<label
											className="block text-gray-700 text-sm font-bold mb-2"
											htmlFor="city"
										>
											City
										</label>
										<input
											className="reg-input"
											type="text"
											id="city"
											{...register('city')}
											defaultValue={client?.city}
										/>
										{errors.city && (
											<span className="error">This field is required</span>
										)}
									</div>
									<div className="field-section-full">
										<label
											className="block text-gray-700 text-sm font-bold mb-2"
											htmlFor="state"
										>
											State
										</label>
										<input
											className="reg-input"
											type="text"
											id="state"
											{...register('state')}
											defaultValue={client?.state}
										/>
										{errors.state && (
											<span className="error">This field is required</span>
										)}
									</div>
									<div className="field-section-full">
										<label
											className="block text-gray-700 text-sm font-bold mb-2"
											htmlFor="postalCode"
										>
											Postal Code
										</label>
										<input
											className="reg-input"
											type="text"
											id="postalCode"
											{...register('postalCode')}
											defaultValue={client?.postalCode}
										/>
										{errors.postalCode && (
											<span className="error">This field is required</span>
										)}
									</div>
									{currentUser?.role === 'SUPER' && (
										<div className="field-section-full">
											<label
												className="block text-gray-700 text-sm font-bold mb-2"
												htmlFor="source"
											>
												Account Manager:
											</label>
											<select
												className="reg-input"
												id="source"
												{...register('source')}
												defaultValue={client?.source}
												onChange={(e) => setValue('source', e.target.value)}
											>
												{accountManager?.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
											{errors.source && (
												<span className="error">This field is required</span>
											)}
										</div>
									)}

									<div className="field-section-full">
										<label
											className="block text-gray-700 text-sm font-bold mb-2"
											htmlFor="status"
										>
											Status
										</label>
										<select
											className="reg-input"
											id="status"
											{...register('status')}
											defaultValue={client?.status}
											onChange={(e) => setValue('status', e.target.value)}
										>
											{statusOptions?.map((option) => (
												<option key={option.value} value={option.value}>
													{option.label}
												</option>
											))}
										</select>
										{errors.status && (
											<span className="error">This field is required</span>
										)}
									</div>
									<hr className="mb-5" />

									<button type="submit" className="btn-save">
										Save
									</button>
									{isSaving && (
										<div style={{ color: 'blue' }}>Saving data...</div>
									)}
									{hasError && (
										<div style={{ color: 'red' }}>
											Error saving data. Please try again.
										</div>
									)}
									{isSaved && (
										<div style={{ color: 'green' }}>
											Data saved successfully!
										</div>
									)}
								</div>
							</div>
						</form>
					</>
				) : (
					<>
						<div className="user-information">
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('info')}
								>
									<h2 className="title-h2">Client Information</h2>
									<button>
										{sections.info ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.info && (
									<div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2 content-block">
										<p className="data-content">
											<strong>First Name:</strong> {client?.firstName}
										</p>
										<p className="data-content">
											<strong>Last Name:</strong> {client?.lastName}
										</p>
										<p className="data-content">
											<strong>Company Name:</strong> {client?.companyName}
										</p>
										<p className="data-content">
											<strong>Email:</strong> {client?.email}
										</p>
										<p className="data-content">
											<strong>Phone Number:</strong>{' '}
											{client?.phoneNumber
												? formatPhoneNumber(client?.phoneNumber)
												: 'N/A'}
										</p>

										<p className="data-content">
											<strong>User Created: </strong>{' '}
											{new Date(client?.createdAt).toLocaleString('en-US', {
												month: '2-digit',
												day: '2-digit',
												year: '2-digit',
												hour: '2-digit',
												minute: '2-digit',
											})}
										</p>
									</div>
								)}
							</div>
						</div>
						<div className="user-information">
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('address')}
								>
									<h2 className="title-h2">Client Address</h2>
									<button>
										{sections.address ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.address && (
									<div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2 content-block">
										<p className="data-content">
											<strong>Street:</strong> {client?.address}
										</p>
										<p className="data-content">
											<strong>City:</strong> {client?.city}
										</p>
										<p className="data-content">
											<strong>State:</strong> {client?.state}
										</p>
										<p className="data-content">
											<strong>Zipcode:</strong> {client?.postalCode}
										</p>
									</div>
								)}
							</div>
						</div>
						<div className="user-information">
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('notes')}
								>
									<h2 className="title-h2">Notes</h2>
									<button>
										{sections.notes ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.notes && (
									<div className=" content-block">
										{/* upload multiple files */}
										<div className="field-section-full formSubmitFile ">
											<form ref={formRef} onSubmit={handleSubmit(onSubmitFile)}>
												<textarea
													placeholder="Add new note"
													className="reg-textarea"
													{...register('textNote', { required: true })}
												/>
												{errors.textNote && (
													<span className="error">This field is required</span>
												)}
												{fileInputs.map((index, i) => (
													<input
														key={`${index}-${i}`}
														type="file"
														name="file"
														className="reg-input"
														onChange={(e) => {
															if (e.target.files.length > 0) {
																fileUploadHandler(e.target.files[0]);
															}
														}}
													/>
												))}

												<button type="button" onClick={removeFileInput}>
													<X size={20} className="ml-2 text-redColor-500" />
												</button>
												<button type="button" onClick={addFileInput}>
													<PlusCircle
														size={20}
														className="ml-2 text-redColor-500"
													/>
												</button>
												{Msg && (
													<strong className="text-greenColor-500 w-full block font-light">
														{Msg}
													</strong>
												)}
												{progress.started && (
													<div className="progress-bar">
														<div
															className="progress-bar-fill"
															style={{ width: `${progress.pc}%` }}
														></div>
														<span>{progress.msg}</span>
													</div>
												)}
												<button
													type="submit"
													className="btn-save"
													// onClick={addNote}
												>
													Save Note
												</button>
											</form>
										</div>
										<div className="content-block">
											{client?.notes &&
												[...client.notes].reverse().map((note, index) => (
													<div key={index} className="notesList">
														<span className="notes-item-title">
															<strong>Date:</strong>{' '}
															{new Date(note?.createdAt).toLocaleString(
																'en-US',
																{
																	month: '2-digit',
																	day: '2-digit',
																	year: '2-digit',
																	hour: '2-digit',
																	minute: '2-digit',
																}
															)}
														</span>
														<p className="notes-item-title">
															{note && note?.userId && (
																<strong>Account manager:</strong>
															)}
															{note &&
																note?.userId &&
																getUserName(note?.userId)}
														</p>
														<span className="whitespace-normal"></span>
														<div className="notes-item-value">
															<strong>Comments:</strong>
															<br />
															{note?.text}
														</div>
														<div className="field-section-full download-array">
															{note?.attachments?.length > 0 ? (
																<strong className="w-full">
																	Download attachments
																</strong>
															) : (
																''
															)}
															{note?.attachments &&
																note.attachments.map((file, index) => (
																	<div
																		key={index}
																		className="notes-item-attachment"
																	>
																		<a
																			href="#"
																			onClick={(e) => {
																				e.preventDefault();
																				window.open(
																					file.url,
																					'_blank',
																					'noopener,noreferrer'
																				);
																			}}
																			className="btn-download-file"
																		>
																			<Download size={12} />
																		</a>
																	</div>
																))}
														</div>
													</div>
												))}
										</div>
									</div>
								)}
							</div>
						</div>
					</>
				)}
			</aside>
		</div>
	);
}
