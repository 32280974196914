import { ChevronDown, ChevronRight } from 'react-feather';
import {
	cSWidthSizesCarport,
	cSWidthSizesWideSpan,
	cSWidthSizesTripleWide,
	cSWidthSizesGarage,
	cSWidthSizesHexbarn,
	cSLengthSizesCarport,
	legHeightOptions,
	rLWidthSizes,
	depthOptions,
} from '../../formData/sizesData';

const SizesComponent = ({
	toggleSection,
	sections,
	setCSWidth,
	cSWidth,
	selectedStyle,
	setCSLength,
	cSLength,
	setCSHeight,
	cSHeight,
	setLLWidth,
	setLLLengh,
	setLLHeight,
	setRLWidth,
	setRLLength,
	setRLHeight,
	centerStorage,
	lLWidth,
	lLLength,
	lLHeight,
	rLWidth,
	rLLength,
	rLHeight,
	dephCentralStorage,
	setDephCentralStorage,
	leftStorage,
	setLeftStorage,
	rightStorage,
	setRightStorage,
	leftLean,
	rightLean,
	backLean,
	frontLean,
}) => {
	const handleDephCentralStorageChange = (e) => {
		const value = parseInt(e.target.value, 10);
		if (value > cSLength) {
			alert(
				`Center storage cannot be greater than the frame length (${cSLength})`
			);
			setDephCentralStorage(cSLength);
		} else {
			setDephCentralStorage(value);
		}
	};

	return (
		<div className="tab-information">
			<div className="tab-information-block">
				<div
					className="title-block"
					onClick={(e) => toggleSection('sizeTab', e)}
				>
					<h2 className="title-h2">Size</h2>
					<button>
						{sections.sizeTab ? (
							<ChevronDown size={24} />
						) : (
							<ChevronRight size={24} />
						)}
					</button>
				</div>
				{sections.sizeTab && (
					<>
						<div className="new-order-form">
							<p className="instructions-p">
								Select the size for each section of your building. Note that the
								selected length is the frame length. The roof length is 1' (one
								foot) longer than the frame, with the roof extending 6" (six
								inches) on each end.
							</p>
							<div className="field-section-inputs">
								<div className="inp-col-size">
									<h3>Center section</h3>
								</div>

								<div className="inp-col-size">
									<label htmlFor="width">Width</label>
									<select
										onChange={(e) => setCSWidth(e.target.value)}
										value={cSWidth}
									>
										{(selectedStyle === 'carport'
											? cSWidthSizesCarport
											: selectedStyle === 'tw'
											? cSWidthSizesTripleWide
											: selectedStyle === 'wsd'
											? cSWidthSizesWideSpan
											: selectedStyle === 'garage' ||
											  selectedStyle === 'barn' ||
											  selectedStyle === 'slbarn' ||
											  selectedStyle === 'utility' ||
											  selectedStyle === 'hexbarn' ||
											  selectedStyle === 'leanto' ||
											  selectedStyle === 'rv'
											? cSWidthSizesGarage
											: selectedStyle === 'hexbarn' ||
											  selectedStyle === 'leanto'
											? cSWidthSizesHexbarn
											: []
										).map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
								<div className="inp-col-size">
									<label htmlFor="length">Frame Length</label>

									<select
										onChange={(e) => setCSLength(e.target.value)}
										value={cSLength}
									>
										{cSLengthSizesCarport.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>

								<div className="inp-col-size">
									<label htmlFor="setCSHeight">Leg Height</label>
									<select
										onChange={(e) => {
											setCSHeight(e.target.value);
										}}
										value={cSHeight}
										className="select-input"
									>
										{legHeightOptions.map((option) => (
											<option key={option.value} value={option?.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>

							{(centerStorage !== 'none' || selectedStyle === 'utility') && (
								<>
									<hr className="mb-5" />

									<div className="field-section-inputs-left">
										<div className="inp-col-size">
											<h3>Center storage</h3>
										</div>
										<div className="inp-col-size">
											<label htmlFor="width">Deph</label>
											<select
												onChange={handleDephCentralStorageChange}
												value={dephCentralStorage}
												className="select-input"
											>
												{depthOptions.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
										</div>
									</div>
								</>
							)}

							{/* Left lean */}
							{(leftLean === 'leanToOnly' ||
								leftLean === 'leanToWithStorage' ||
								selectedStyle === 'barn' ||
								selectedStyle === 'hexbarn') && (
								<>
									<hr className="mb-5" />
									<div className="field-section-inputs">
										<div className="inp-col-size">
											<h3>Left Lean</h3>
										</div>
										<div className="inp-col-size">
											<label htmlFor="width">Width</label>
											<select
												onChange={(e) => setLLWidth(e.target.value)}
												value={lLWidth}
												className="select-input"
											>
												{rLWidthSizes.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
										</div>
										<div className="inp-col-size">
											<label htmlFor="length">Frame Length</label>
											<select
												onChange={(e) => setLLLengh(e.target.value)}
												className="select-input"
												value={lLLength}
											>
												{cSLengthSizesCarport.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
										</div>

										<div className="inp-col-size">
											<label htmlFor="height">Leg Height</label>
											<select
												onChange={(e) => {
													setLLHeight(e.target.value);
												}}
												value={lLHeight}
												className="select-input"
											>
												{legHeightOptions.map((option) => (
													<option key={option.value} value={option?.value}>
														{option.label}
													</option>
												))}
											</select>
										</div>
									</div>
									{leftLean === 'leanToWithStorage' && (
										<>
											<hr className="mb-5" />

											<div className="field-section-inputs-left">
												<div className="inp-col-size">
													<h3>Left storage</h3>
												</div>
												<div className="inp-col-size">
													<label htmlFor="width">Deph</label>
													<select
														onChange={handleDephCentralStorageChange}
														value={dephCentralStorage}
														className="select-input"
													>
														{depthOptions.map((option) => (
															<option key={option.value} value={option.value}>
																{option.label}
															</option>
														))}
													</select>
												</div>
											</div>
										</>
									)}
								</>
							)}

							{/* // Right lean */}
							{(rightLean === 'leanToOnly' ||
								rightLean === 'leanToWithStorage' ||
								selectedStyle === 'barn' ||
								selectedStyle === 'hexbarn') && (
								<>
									<hr className="mb-5" />
									<div className="field-section-inputs">
										<div className="inp-col-size">
											<h3>Right Lean</h3>
										</div>
										<div className="inp-col-size">
											<label htmlFor="width">Width</label>
											<select
												onChange={(e) => setRLWidth(e.target.value)}
												value={rLWidth}
												className="select-input"
											>
												{rLWidthSizes.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
										</div>
										<div className="inp-col-size">
											<label htmlFor="length">Frame Length</label>
											<select
												onChange={(e) => setRLLength(e.target.value)}
												value={rLLength}
												className="select-input"
											>
												{cSLengthSizesCarport.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
										</div>
										<div className="inp-col-size">
											<label htmlFor="height">Leg Height</label>
											<select
												onChange={(e) => setRLHeight(e.target.value)}
												value={rLHeight}
												className="select-input"
											>
												{legHeightOptions.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
										</div>
									</div>
									{rightLean === 'leanToWithStorage' && (
										<>
											<hr className="mb-5" />
											<div className="field-section-inputs-left">
												<div className="inp-col-size">
													<h3>Right storage</h3>
												</div>
												<div className="inp-col-size">
													<label htmlFor="width">Deph</label>
													<select
														onChange={handleDephCentralStorageChange}
														value={dephCentralStorage}
														className="select-input"
													>
														{depthOptions.map((option) => (
															<option key={option.value} value={option.value}>
																{option.label}
															</option>
														))}
													</select>
												</div>
											</div>
										</>
									)}
								</>
							)}

							{/* Front lean */}

							{(frontLean === 'leanToOnly' ||
								frontLean === 'leanToWithStorage' ||
								selectedStyle === 'barn' ||
								selectedStyle === 'hexbarn') && (
								<>
									<hr className="mb-5" />
									<div className="field-section-inputs">
										<div className="inp-col-size">
											<h3>Front Lean</h3>
										</div>
										<div className="inp-col-size">
											<label htmlFor="width">Width</label>
											<select
												onChange={(e) => setRLWidth(e.target.value)}
												value={rLWidth}
												className="select-input"
											>
												{rLWidthSizes.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
										</div>
										<div className="inp-col-size">
											<label htmlFor="length">Frame Length</label>
											<select
												onChange={(e) => setRLLength(e.target.value)}
												value={rLLength}
												className="select-input"
											>
												{cSLengthSizesCarport.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
										</div>
										<div className="inp-col-size">
											<label htmlFor="height">Leg Height</label>
											<select
												onChange={(e) => setRLHeight(e.target.value)}
												value={rLHeight}
												className="select-input"
											>
												{legHeightOptions.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
										</div>
									</div>
									{frontLean === 'leanToWithStorage' && (
										<>
											<hr className="mb-5" />
											<div className="field-section-inputs-left">
												<div className="inp-col-size">
													<h3>Back storage</h3>
												</div>
												<div className="inp-col-size">
													<label htmlFor="width">Deph</label>
													<select
														onChange={handleDephCentralStorageChange}
														value={dephCentralStorage}
														className="select-input"
													>
														{depthOptions.map((option) => (
															<option key={option.value} value={option.value}>
																{option.label}
															</option>
														))}
													</select>
												</div>
											</div>
										</>
									)}
								</>
							)}

							{/* Back lean */}
							{(backLean === 'leanToOnly' ||
								backLean === 'leanToWithStorage' ||
								selectedStyle === 'barn' ||
								selectedStyle === 'hexbarn') && (
								<>
									<hr className="mb-5" />
									<div className="field-section-inputs">
										<div className="inp-col-size">
											<h3>Back Lean</h3>
										</div>
										<div className="inp-col-size">
											<label htmlFor="width">Width</label>
											<select
												onChange={(e) => setRLWidth(e.target.value)}
												value={rLWidth}
												className="select-input"
											>
												{rLWidthSizes.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
										</div>
										<div className="inp-col-size">
											<label htmlFor="length">Frame Length</label>
											<select
												onChange={(e) => setRLLength(e.target.value)}
												value={rLLength}
												className="select-input"
											>
												{cSLengthSizesCarport.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
										</div>
										<div className="inp-col-size">
											<label htmlFor="height">Leg Height</label>
											<select
												onChange={(e) => setRLHeight(e.target.value)}
												value={rLHeight}
												className="select-input"
											>
												{legHeightOptions.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
										</div>
									</div>
									{backLean === 'leanToWithStorage' && (
										<>
											<hr className="mb-5" />
											<div className="field-section-inputs-left">
												<div className="inp-col-size">
													<h3>Back storage</h3>
												</div>
												<div className="inp-col-size">
													<label htmlFor="width">Deph</label>
													<select
														onChange={handleDephCentralStorageChange}
														value={dephCentralStorage}
														className="select-input"
													>
														{depthOptions.map((option) => (
															<option key={option.value} value={option.value}>
																{option.label}
															</option>
														))}
													</select>
												</div>
											</div>
										</>
									)}
								</>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default SizesComponent;
