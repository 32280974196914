import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { createPayment, updatePayment } from '../../slices/billingSlice.tsx';
import { fetchOrderById } from '../../slices/ordersSlice.tsx';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { X } from 'react-feather';

const schema = yup.object().shape({
	paidAmount: yup.number().required(),
	dueAmount: yup.number().required(),
	paymentDate: yup.string().required(),
	paymentMethod: yup.string().required(),
	status: yup.number().required(),
});

export default function BillingDetails({ order }) {
	const dispatch = useDispatch();
	const [isEditing, setIsEditing] = useState(false);
	const [selectedDate, setSelectedDate] = useState(null);
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});

	const formatToTwoDecimals = (value) => {
		return Number(parseFloat(value).toFixed(2));
	};

	useEffect(() => {
		if (order?.invoice && order?.invoice.paymentDate) {
			setSelectedDate(new Date(order?.invoice.paymentDate));
		}
	}, [order]);

	const onSubmit = async (data) => {
		try {
			const formattedData = {
				paidAmount: parseFloat(parseFloat(data.paidAmount).toFixed(2)),
				dueAmount: parseFloat(parseFloat(data.dueAmount).toFixed(2)),
				paymentDate: new Date(data.paymentDate).toISOString(),
				paymentMethod: data.paymentMethod,
				orderId: order.id,
				status: data.status,
			};
			await dispatch(createPayment(formattedData));
			await dispatch(fetchOrderById(order?.id));
			setIsEditing(false);
		} catch (error) {
			console.error('Error updating invoice:', error);
		}
	};

	const onUpdateInvoice = async (data) => {
		try {
			const formattedData = {
				id: order.invoice.id,
				paidAmount: parseFloat(parseFloat(data.paidAmount).toFixed(2)),
				dueAmount: parseFloat(parseFloat(data.dueAmount).toFixed(2)),
				paymentDate: data.paymentDate
					? new Date(data.paymentDate).toISOString()
					: null,
				paymentMethod: data.paymentMethod,
				orderId: order.id,
				status: data.status,
			};
			await dispatch(updatePayment(formattedData));
			await dispatch(fetchOrderById(order?.id));
			setIsEditing(false);
		} catch (error) {
			console.error('Error updating invoice:', error);
		}
	};

	return (
		<div className="flex flex-col">
			{!isEditing ? (
				<>
					{order?.invoice?.id ? (
						<>
							<div className="flex items-center">
								<span className="text-sm font-semibold">Status:</span>
								<span className="ml-2">
									{order.invoice.status === 1 ? (
										<span className="text-red-500">Unsettled</span>
									) : (
										<span className="text-green-500">Paid</span>
									)}
								</span>
								<span className="ml-2">
									{order.invoice.status === 1 ? (
										<span className="w-3 h-3 bg-red-500 rounded-full inline-block"></span>
									) : (
										<span className="w-3 h-3 bg-green-500 rounded-full inline-block"></span>
									)}
								</span>
							</div>

							<hr className="my-4" />
							<table className="w-full mt-4">
								<thead>
									<tr>
										<th>Date</th>
										<th>Payment Method</th>
										<th>Amount</th>
										<th>Due</th>
									</tr>
								</thead>

								<tbody>
									<tr>
										<td>
											{format(
												new Date(order.invoice.paymentDate),
												'MM/dd/yyyy'
											)}
										</td>
										<td>{order.invoice.paymentMethod}</td>
										<td>${order.invoice.paidAmount.toFixed(2)}</td>
										<td>${order.invoice.dueAmount.toFixed(2)}</td>
									</tr>
								</tbody>
							</table>

							<button
								onClick={() => setIsEditing(true)}
								className="redBtn w-1/4 mt-4"
							>
								Edit Invoice
							</button>
						</>
					) : (
						<>
							<h2>
								{order?.invoice?.id ? 'Update Invoice' : 'Create New Invoice'}
							</h2>
							<form
								onSubmit={handleSubmit(onSubmit)}
								className="flex flex-col mt-4"
							>
								<div className="flex flex-col md:flex-row">
									<div className="flex flex-col w-full md:w-1/2 md:pr-4">
										<label className="text-sm font-semibold pb-5">
											Paid Amount
										</label>
										<input
											type="text"
											className="input  p-2 cursor-pointer"
											{...register('paidAmount')}
											onBlur={(e) =>
												setValue(
													'paidAmount',
													parseFloat(e.target.value).toFixed(2)
												)
											}
										/>
										{errors.paidAmount && <p>{errors.paidAmount.message}</p>}
									</div>
									<div className="flex flex-col w-full md:w-1/2 md:pl-4">
										<label className="text-sm font-semibold pb-5">
											Due Amount
										</label>
										<input
											type="text"
											className="input p-2 cursor-pointer"
											{...register('dueAmount')}
											onBlur={(e) =>
												setValue(
													'dueAmount',
													parseFloat(e.target.value).toFixed(2)
												)
											}
										/>
										{errors.dueAmount && <p>{errors.dueAmount.message}</p>}
									</div>
								</div>
								<div className="flex flex-col md:flex-row mt-4">
									<div className="flex flex-col w-full md:w-1/2 md:pr-4">
										<label className="text-sm font-semibold pb-5">
											Payment Date
										</label>
										<DatePicker
											selected={selectedDate}
											onChange={(date) => {
												setSelectedDate(date);
												setValue('paymentDate', date);
											}}
											className="input p-2 cursor-pointer"
										/>
										{errors.paymentDate && <p>{errors.paymentDate.message}</p>}
									</div>
									<div className="flex flex-col w-full md:w-1/2 md:pl-4">
										<label className="text-sm font-semibold pb-5">
											Payment Method
										</label>
										<input
											type="text"
											className="input p-2 cursor-pointer"
											{...register('paymentMethod')}
										/>
										{errors.paymentMethod && (
											<p>{errors.paymentMethod.message}</p>
										)}
									</div>
								</div>
								<div className="flex flex-col md:flex-row mt-4">
									<div className="flex flex-col w-full md:w-1/2 md:pr-4">
										<label className="text-sm font-semibold pb-5">Status</label>
										<select
											className="input w-52 p-2 cursor-pointer"
											{...register('status', { required: true })}
										>
											<option value="">Select Status</option>
											<option value="1">Partially Paid</option>
											<option value="2">Paid</option>
										</select>
										{errors.status && <p>{errors.status.message}</p>}
									</div>
								</div>
								<button type="submit" className="redBtn w-1/4 mt-4">
									{isEditing ? 'Update Invoice' : 'Create Invoice'}
								</button>
							</form>{' '}
						</>
					)}
				</>
			) : (
				<>
					<button onClick={() => setIsEditing(false)} className="close-btn-2">
						<X size={24} />
					</button>
					<h2>
						{order?.invoice?.id ? 'Update Invoice' : 'Create New Invoice'}
					</h2>
					<form
						onSubmit={handleSubmit(onUpdateInvoice)}
						className="flex flex-col mt-4"
					>
						<div className="flex flex-col md:flex-row">
							<div className="flex flex-col w-full md:w-1/2 md:pr-4">
								<label className="text-sm font-semibold pb-5">
									Paid Amount
								</label>
								<input
									type="text"
									className="input p-2 "
									defaultValue={parseFloat(order.invoice.paidAmount).toFixed(2)}
									disabled={!isEditing}
									{...register('paidAmount')}
									onBlur={(e) =>
										setValue(
											'paidAmount',
											parseFloat(e.target.value).toFixed(2)
										)
									}
								/>
								{errors.paidAmount && <p>{errors.paidAmount.message}</p>}
							</div>
							<div className="flex flex-col w-full md:w-1/2 md:pl-4">
								<label className="text-sm font-semibold pb-5">Due Amount</label>
								<input
									type="text"
									className="input p-2 "
									defaultValue={parseFloat(order.invoice.dueAmount).toFixed(2)}
									disabled={!isEditing}
									{...register('dueAmount')}
									onBlur={(e) =>
										setValue('dueAmount', parseFloat(e.target.value).toFixed(2))
									}
								/>
								{errors.dueAmount && <p>{errors.dueAmount.message}</p>}
							</div>
						</div>
						<div className="flex flex-col md:flex-row mt-4">
							<div className="flex flex-col w-full md:w-1/2 md:pr-4">
								<label className="text-sm font-semibold pb-5">
									Payment Date
								</label>
								<DatePicker
									selected={
										order.invoice && order.invoice.paymentDate
											? new Date(order.invoice.paymentDate)
											: null
									}
									onChange={(date) => setValue('paymentDate', date)}
									className="input p-2 cursor-pointer"
									disabled={!isEditing}
								/>
								{errors.paymentDate && <p>{errors.paymentDate.message}</p>}
							</div>
							<div className="flex flex-col w-full md:w-1/2 md:pl-4">
								<label className="text-sm font-semibold pb-5">
									Payment Method
								</label>
								<select
									type="text"
									className="input p-2 cursor-pointer"
									defaultValue={order.invoice.paymentMethod}
									disabled={!isEditing}
									{...register('paymentMethod')}
								>
									<option value="Credit card">Credit card</option>
									<option value="Debit card">Debit card</option>
									<option value="Cash">Cash</option>
									<option value="Money order">Money order</option>
									<option value="Cashier's checks">Cashier's checks</option>
								</select>
								{errors.paymentMethod && <p>{errors.paymentMethod.message}</p>}
							</div>
						</div>
						<div className="flex flex-col md:flex-row mt-4">
							<div className="flex flex-col w-full md:w-1/2 md:pr-4">
								<label className="text-sm font-semibold pb-5">Status</label>

								<select
									className="input p-2 cursor-pointer"
									{...register('status', { required: true })}
								>
									<option value="1">Partially Paid</option>
									<option value="2">Paid</option>
								</select>
								{errors.status && <p>{errors.status.message}</p>}
							</div>
						</div>
						<button type="submit" className="redBtn w-1/4 mt-4">
							{isEditing ? 'Update Invoice' : 'Create Invoice'}
						</button>
					</form>{' '}
				</>
			)}
		</div>
	);
}
