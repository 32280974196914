import { useEffect } from 'react';

const StyleSettings = ({
	selectedStyle,
	setCSWidth,
	setCSLength,
	setCSHeight,
	setLLWidth,
	setLLLengh,
	setLLHeight,
	setRLWidth,
	setRLLength,
	setRLHeight,
}) => {
	useEffect(() => {
		if (selectedStyle === 'barn') {
			setCSWidth('15');
			setCSLength('20');
			setCSHeight('10');
			setLLWidth('10');
			setLLLengh('20');
			setLLHeight('6');
			setRLWidth('10');
			setRLLength('20');
			setRLHeight('6');
		} else if (selectedStyle === 'carport') {
			setCSWidth('20');
			setCSLength('30');
			setCSHeight('6');
			setLLWidth('');
			setLLLengh('');
			setLLHeight('');
			setRLWidth('');
			setRLLength('');
			setRLHeight('');
		} else if (selectedStyle === 'tw') {
			setCSWidth('26');
			setCSLength('30');
			setCSHeight('8');
			setLLWidth('');
			setLLLengh('');
			setLLHeight('');
			setRLWidth('');
			setRLLength('');
			setRLHeight('');
		} else if (selectedStyle === 'wsd') {
			setCSWidth('32');
			setCSLength('20');
			setCSHeight('10');
			setLLWidth('');
			setLLLengh('');
			setLLHeight('');
			setRLWidth('');
			setRLLength('');
			setRLHeight('');
		} else if (selectedStyle === 'leanto') {
			setCSWidth('14');
			setCSLength('30');
			setCSHeight('10');
			setLLWidth('');
			setLLLengh('');
			setLLHeight('');
			setRLWidth('');
			setRLLength('');
			setRLHeight('');
		} else if (selectedStyle === 'slbarn') {
			setCSWidth('12');
			setCSLength('20');
			setCSHeight('10');
			setLLWidth('12');
			setLLLengh('20');
			setLLHeight('7');
			setRLWidth('12');
			setRLLength('20');
			setRLHeight('7');
		} else if (selectedStyle === 'hexbarn') {
			setCSWidth('20');
			setCSLength('20');
			setCSHeight('8');
			setLLWidth('');
			setLLLengh('');
			setLLHeight('');
			setRLWidth('');
			setRLLength('');
			setRLHeight('');
		} else if (selectedStyle === 'garage') {
			setCSWidth('25');
			setCSLength('35');
			setCSHeight('8');
			setLLWidth('');
			setLLLengh('');
			setLLHeight('');
			setRLWidth('');
			setRLLength('');
			setRLHeight('');
		} else if (selectedStyle === 'rv') {
			setCSWidth('30');
			setCSLength('51');
			setCSHeight('16');
			setLLWidth('');
			setLLLengh('');
			setLLHeight('');
			setRLWidth('');
			setRLLength('');
			setRLHeight('');
		} else if (selectedStyle === 'utility') {
			setCSWidth('20');
			setCSLength('36');
			setCSHeight('9');
			setLLWidth('');
			setLLLengh('');
			setLLHeight('');
			setRLWidth('');
			setRLLength('');
			setRLHeight('');
		} else {
			// Reset to default values for other styles
			setCSWidth('20');
			setCSLength('30');
			setCSHeight('6');
			setLLWidth('');
			setLLLengh('');
			setLLHeight('');
			setRLWidth('');
			setRLLength('');
			setRLHeight('');
		}
	}, [selectedStyle]);

	return null;
};

export default StyleSettings;
